import React from 'react'
import NextImage from 'next/image'
import NextLink from 'next/link'

import { Badge } from '@wartek-id/badge'
import { Text } from '@wartek-id/text-v1'

import { KeyPairValueKeys, useGetKeyPairValue } from 'api/core/useAPIKeyValue'
import { useAuth } from 'utils/auth'
import { getUserDisplayName } from 'app/Homepage/utils/user'
import { useSETrackEvent } from 'app/ConversationPage/hooks/useSETrackEvent'
import { useConversationEntryPointStore } from 'stores/conversation'

type TConversationEntryPoint = {
  copy: string[]
}

const ConversationEntryPointVariantA = () => {
  const { session } = useAuth()
  const { isRendered, entryPointText, setRendered, setEntryPointText } =
    useConversationEntryPointStore()
  const { SETrackConversationPage } = useSETrackEvent()

  const userDisplayName = React.useMemo(
    () => getUserDisplayName(session?.user?.name),
    [session?.user?.name]
  )

  const { data, isLoading, isSuccess } =
    useGetKeyPairValue<TConversationEntryPoint>(
      KeyPairValueKeys.ConversationEntryPoint
    )
  const entryPointData = React.useMemo<
    TConversationEntryPoint | Record<string, never>
  >(() => (isSuccess ? data?.data : {}), [data?.data, isSuccess])

  React.useEffect(() => {
    const entryPointTexts = entryPointData?.copy ?? []
    if (entryPointData?.copy) {
      setEntryPointText(
        entryPointData.copy[
          Math.floor(Math.random() * entryPointTexts.length)
        ].replace('<name>', userDisplayName)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryPointData])

  const clickHandler = React.useCallback(() => {
    SETrackConversationPage('CLICK_GURU_AI_ENTRY', {
      screen_name: 'HOMEPAGE',
      event_trigger: 'HOMEPAGE',
      text_output: entryPointText,
      variant: 'variant_1',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!isRendered) {
      SETrackConversationPage('VIEW_GURU_AI_ENTRYPOINT', {
        screen_name: 'HOMEPAGE',
        event_trigger: 'HOMEPAGE',
        text_output: entryPointText,
        variant: 'variant_1',
      })
      setRendered()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return null
  }

  return (
    <div
      data-testid="conversation-entry-point"
      className="relative py-4 bg-white"
    >
      <div className="px-4">
        <NextLink href="/asisten-guru" passHref>
          <a
            data-testid="conversation-entry-point-button"
            onClick={clickHandler}
          >
            <div className="relative p-4 space-x-2 rounded-lg bg-surface-selected-default">
              <div className="max-w-[calc(100%-80px)]">
                <div className="flex items-center mb-1 space-x-1">
                  <div>
                    <Text
                      variant="body-sm"
                      className="!font-semibold !text-blue-60"
                    >
                      Asisten Guru
                    </Text>
                  </div>
                  <div>
                    <Badge className="!text-blue-60 !bg-[#CEE3FC] !border-[#CEE3FC]">
                      Beta
                    </Badge>
                  </div>
                </div>

                <div className="mb-4">
                  <Text variant="heading-sm">{entryPointText}</Text>
                </div>

                <div>
                  <button className="flex p-2 rounded-md bg-blue-70">
                    <Text
                      variant="body-sm"
                      color="inverse"
                      className="!font-semibold"
                    >
                      Tanya sekarang
                    </Text>
                  </button>
                </div>
              </div>

              <div className="absolute -top-1 -right-1">
                <NextImage
                  src="/images/asisten-guru/avatar.png"
                  width="85px"
                  height="77px"
                  alt="Avatar Asisten Guru"
                />
              </div>
            </div>
          </a>
        </NextLink>
      </div>
    </div>
  )
}

export default ConversationEntryPointVariantA
