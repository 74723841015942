import NextLink from 'next/link'

export interface IProductLinkProps
  extends React.HTMLAttributes<HTMLAnchorElement> {
  isExternalLink?: boolean
  link: string
  children: React.ReactNode
}
export const ProductLink = ({
  isExternalLink = false,
  children,
  link,
  ...props
}: IProductLinkProps) => {
  return isExternalLink ? (
    <a {...props} href={link}>
      {children}
    </a>
  ) : (
    <NextLink href={link} passHref>
      <a {...props}>{children}</a>
    </NextLink>
  )
}
