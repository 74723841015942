import NextImage from 'next/image'

import { Text } from '@wartek-id/text-v1'

import { ProductLink } from 'app/Homepage/components/ProductLink'

interface IProductItemProps extends React.HTMLAttributes<HTMLAnchorElement> {
  image: string
  title: string
  isExternalLink?: boolean
  link: string
}
const ProductItem = ({ image, title, ...props }: IProductItemProps) => {
  return (
    <ProductLink
      {...props}
      className="col-span-1 grow-0 flex flex-col items-center text-center"
    >
      <div>
        <NextImage src={image} alt={`Ikon ${title}`} width={40} height={40} />
      </div>
      <Text variant="helper">{title}</Text>
    </ProductLink>
  )
}

export default ProductItem
