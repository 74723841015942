import React from 'react'
import NextHead from 'next/head'
import { useRouter } from 'next/router'

import NavigationBar from 'app/Homepage/components/NavigationBar'
import Hero from 'app/RuangGtkHomePage/components/Hero'
import Products from 'app/RuangGtkHomePage/components/Products'
import SearchBox from 'app/RuangGtkHomePage/components/SearchBox'
import { KSSelectionCard } from 'app/Homepage/components/KSSelectionCard'

interface IHomepageProps {
  isKinerjaEntryPointEnabled: boolean
}
const RuangGtkHomePage = ({ isKinerjaEntryPointEnabled }: IHomepageProps) => {
  const router = useRouter()

  React.useEffect(() => {
    router.prefetch('/pencarian')
  }, [router])

  return (
    <>
      <NextHead>
        <title>Beranda - Ruang GTK</title>
        <meta
          name="description"
          content="Temukan beragam bahan ajar, buku teks, modul ajar, paket soal asesmen, serta program pelatihan untuk mendukung guru melakukan pembelajaran yang merdeka."
        />
      </NextHead>

      <div className="bg-[#FBFDFE] text-gtk-basic scroll-smooth">
        {/* Search Bar */}
        <SearchBox />
        {/* Hero */}
        <Hero />

        <KSSelectionCard />
        {/* Products */}
        <Products isKinerjaEntryPointEnabled={isKinerjaEntryPointEnabled} />
      </div>

      <NavigationBar isRuangGtkEnabled />
    </>
  )
}

export default RuangGtkHomePage
