import React from 'react'
import NextImage from 'next/image'

import { Button } from '@wartek-id/button-v1'
import { Icon } from '@wartek-id/icon'
import { Sheet, SheetOverlay, SheetContent } from '@wartek-id/sheet-v1'
import { Text, type TextProps } from '@wartek-id/text'

import { CONVERSATION_ONBOARDING_IDEAS } from 'app/ConversationPage/constants'
import { useConversationOnboardingStore } from 'stores/conversation'

const ConversationOnboarding = () => {
  const { isOnboardingFinished, setOnboardingFinished } =
    useConversationOnboardingStore()

  const closeHandler = () => {
    setOnboardingFinished()
  }

  return (
    <Sheet sheetSize="xl" autoFocus={false} isOpen={!isOnboardingFinished}>
      <SheetOverlay />
      <SheetContent className="max-w-xl m-auto">
        <div className="flex flex-row items-center justify-start p-4 space-x-2 !overflow-hidden">
          <Button iconOnly variant="ghost" onClick={closeHandler}>
            <Icon>close</Icon>
          </Button>
          <Text variant="heading-sm">Fitur baru: Asisten Guru</Text>
        </div>

        <div className="pb-20 space-y-4">
          <div>
            <NextImage
              src="https://uploads.belajar.id/assets/guru-ai.gif"
              layout="responsive"
              width="360px"
              height="200px"
              placeholder="blur"
              blurDataURL="/images/guru-ai-onboarding-placeholder.png"
            />
          </div>

          <div className="px-4">
            <Text variant="heading-lg">
              Diskusi kebutuhan mengajar dengan{' '}
              <span className="text-blue-60">kecerdasan buatan</span>!
            </Text>
          </div>

          <div className="px-4 space-y-3">
            {CONVERSATION_ONBOARDING_IDEAS.map(
              ({ text, textVariant, icon }, index) => {
                return (
                  <div
                    key={`conversation-idea-${index}`}
                    className="flex p-4 space-x-2 rounded-xl bg-default"
                  >
                    <div className="grow-0">
                      <NextImage src={icon} width="24px" height="24px" />
                    </div>
                    <div className="grow">
                      <Text variant={textVariant as TextProps['variant']}>
                        {text}
                      </Text>
                    </div>
                  </div>
                )
              }
            )}
          </div>
        </div>

        <div className="absolute bottom-0 left-0 right-0 px-4 py-3 bg-white">
          <Button color="blue" fullWidth onClick={closeHandler}>
            Coba diskusi sekarang
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}

export default ConversationOnboarding
