import React from 'react'
import { useRouter } from 'next/router'

// API Interfaces
import AndroidHandler from 'utils/androidHandler'
import {
  CPKvKeys,
  useGetCPKeyPairValue,
} from 'api/content-platform/useAPIKeyValue'
import { MILKvKeys, useGetKeyPairValue } from 'api/microlearning/useAPIKeyValue'
// Components
import { useGATrackEvent } from 'app/Homepage/hooks/useGATrackEvent'
import { useSETrackEvent } from 'app/Homepage/hooks/useSETrackEvent'
import PeriodGuard from 'app/PemetaanKompetensiPage/components/PeriodGuard'
import SectionGroup from 'app/Homepage/components/SectionGroup'
import ProductItem from 'app/Homepage/components/ProductItem'
// Misc
import {
  TRACKER_PEMKOM_QUERY_SOURCE_KEY,
  TRACKER_PEMKOM_QUERY_SOURCE_VALUE_HOMEPAGE,
} from 'app/PemetaanKompetensiPage/hooks/useSETrackEvent'
import { TRACKER_REFKOM_QUERY_SOURCE_KEY } from 'app/PemetaanKompetensiPage/hooks/useSETrackEventRefkom'
import {
  IS_ASSESSMENT_REVAMP_ENABLED,
  IS_UJIKOM_ENTRY_HOME_ENABLED,
  IS_LMS_PMM_HOMEPAGE_ENTRY_ENABLED,
} from 'configs/toggle'
import {
  useUserGroupSjt,
  useUserGroupPpgDaljab,
} from 'utils/hooks/useUserGroup'
import CompatibilityDialog from './CompatibilityDialog'
import useSKPTrackEvent from 'app/KinerjaPage/hooks/useSKPTrackEvent'
import { useMediaQueryHelper } from 'app/PemetaanKompetensiPage/hooks/useMediaQueryHelper'
interface IProductsProps {
  currentDateTimeServer: string
  isKinerjaEntryPointEnabled: boolean
}
const Products = ({
  currentDateTimeServer,
  isKinerjaEntryPointEnabled,
}: IProductsProps) => {
  const androidHandler = AndroidHandler()
  const router = useRouter()

  const { hasAccess: hasPpgDaljabAccess } = useUserGroupPpgDaljab()
  const PPG_DALJAB_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/ppg-daljab`

  const { data: featureFlagData } = useGetKeyPairValue(MILKvKeys.FeatureFlag)

  const shouldCheckingRolePpgDaljab =
    featureFlagData?.data?.enableSertifikasiPendidikCheckGroupRole

  const shouldShowPpgDaljabEntryPoint = shouldCheckingRolePpgDaljab
    ? hasPpgDaljabAccess
    : featureFlagData?.data?.enableSertifikasiPendidik

  const shouldShowRefleksiKompetensiEntryPoint =
    featureFlagData?.data?.enableRefleksiKompetensiEntrypoint

  // Uji Kompetensi
  const { hasAccess: hasNecessaryRole, isLoading: isLoadingNecessaryRole } =
    useUserGroupSjt()
  const isUjikomWhitelisted = !isLoadingNecessaryRole && hasNecessaryRole
  const { data: ujikomEntryPointData, isSuccess: ujikomEntryPointDataSuccess } =
    useGetCPKeyPairValue(
      CPKvKeys.FeatureFlag,
      isUjikomWhitelisted && IS_UJIKOM_ENTRY_HOME_ENABLED
    )
  const shouldShowUjikomEntryPoint =
    ujikomEntryPointDataSuccess &&
    !!ujikomEntryPointData?.data?.ujikomEntryPoint

  // LMS
  const shouldShowLMSEntryPoint =
    !androidHandler.enabled && IS_LMS_PMM_HOMEPAGE_ENTRY_ENABLED

  // Trackers
  const { GATrackEventHomePage } = useGATrackEvent()
  const { SETrackEventHomePage } = useSETrackEvent()
  const { trackEvent: trackEventSKP } = useSKPTrackEvent()

  const trackEventHomePage = (activityType: string): void => {
    GATrackEventHomePage({
      activityType,
    })
    SETrackEventHomePage(activityType)
  }

  // Helpers
  const { isDesktop } = useMediaQueryHelper()

  return (
    <div className="px-4 space-y-5">
      <SectionGroup title="Pengembangan diri" className="!py-0">
        <div className="grid grid-cols-4 col-span-2 pt-2 gap-x-2 gap-y-4">
          <ProductItem
            data-testid="home-menu-pelatihan-mandiri"
            title="Pelatihan Mandiri"
            link="/pelatihan-mandiri"
            image="/icon/menu-pelatihan-mandiri.svg"
            onClick={() => {
              trackEventHomePage('CLICK_MICRO_LEARNING')
            }}
          />

          <ProductItem
            data-testid="home-menu-komunitas"
            title="Komunitas"
            isExternalLink
            link={`${process.env.NEXT_PUBLIC_BASE_URL}/komunitas?source=HOMEPAGE`}
            image="/icon/menu-komunitas.svg"
            onClick={() => {
              trackEventHomePage('CLICK_LEARNING_COMMUNITY')
            }}
          />

          <ProductItem
            data-testid="home-menu-seleksi-kepala-sekolah"
            title="Seleksi Kepala Sekolah"
            link="/bcks/selection"
            image="/icon/menu-seleksi-kepsek.svg"
            onClick={() => {
              trackEventHomePage('CLICK_SELEKSI_KS')
            }}
          />

          {shouldShowUjikomEntryPoint && (
            <PeriodGuard currentDateTimeServer={currentDateTimeServer}>
              <ProductItem
                data-testid="home-menu-uji-kompetensi"
                title="Uji Kompetensi"
                link={`/pemetaan-kompetensi?${TRACKER_PEMKOM_QUERY_SOURCE_KEY}=${TRACKER_PEMKOM_QUERY_SOURCE_VALUE_HOMEPAGE}`}
                image="/icon/menu-uji-kompetensi.png"
                onClick={() => {
                  trackEventHomePage('CLICK_UJI_KOMPETENSI')
                }}
              />
            </PeriodGuard>
          )}

          {shouldShowRefleksiKompetensiEntryPoint && (
            <ProductItem
              data-testid="home-menu-refleksi-kompetensi"
              title="Refleksi Kompetensi"
              link={`/refleksi-kompetensi?${TRACKER_REFKOM_QUERY_SOURCE_KEY}=pmm_home`}
              image="/icon/menu-refleksi-kompetensi.png"
              onClick={() => {
                trackEventHomePage('CLICK_REFLEKSI_KOMPETENSI')
              }}
            />
          )}

          {shouldShowLMSEntryPoint && (
            <ProductItem
              data-testid="home-menu-lms"
              title="LMS"
              link={process.env.NEXT_PUBLIC_LMS_URL}
              image="/icon/lms-monitor.png"
              onClick={() => {
                trackEventHomePage('CLICK_LMS_HOMEPAGE_ENTRY_POINT')
              }}
            />
          )}

          {isKinerjaEntryPointEnabled && (
            <CompatibilityDialog
              trigger={({ toggleDialog }) => (
                <ProductItem
                  data-testid="home-menu-kinerja"
                  title="Pengelolaan Kinerja"
                  link=""
                  image="/icon/menu-pengelolaan-kinerja.svg"
                  onClick={() => {
                    if (isDesktop) {
                      router.push('/pengelolaan-kinerja')
                    } else {
                      toggleDialog?.()
                    }
                    trackEventHomePage('CLICK_PENGELOLAAN_KINERJA')
                    trackEventSKP({
                      event_name: 'CLICK_PENGELOLAAN_KINERJA',
                      screen_name: 'WEB_HOMEPAGE',
                    })
                  }}
                />
              )}
              onContinue={() => {
                router.push('/pengelolaan-kinerja')
              }}
            />
          )}

          {shouldShowPpgDaljabEntryPoint && (
            <ProductItem
              data-testid="home-menu-ppg-daljab"
              title="Sertifikasi Pendidik"
              link={PPG_DALJAB_URL}
              image="/icon/img-PPG-Daljab.png"
              onClick={() => {
                trackEventHomePage('CLICK_PPG_DALJAB')
              }}
            />
          )}
        </div>
      </SectionGroup>

      <SectionGroup title="Mengajar" className="!py-0">
        <div className="grid grid-cols-4 col-span-2 pt-2 gap-x-2 gap-y-4">
          <ProductItem
            data-testid="home-menu-cp-atp"
            title="CP/ATP"
            link="/kurikulum/referensi-penerapan/capaian-pembelajaran/"
            image="/icon/menu-cp-atp-alt.svg"
            onClick={() => {
              trackEventHomePage('CLICK_CP_ATP')
            }}
          />

          <ProductItem
            data-testid="home-menu-perangkat-ajar"
            title="Perangkat Ajar"
            link="/perangkat-ajar"
            image="/icon/menu-perangkat-ajar.svg"
            onClick={() => {
              trackEventHomePage('CLICK_TOOLKIT_PAGE')
            }}
          />

          <ProductItem
            data-testid="home-menu-asesmen"
            title="Asesmen Murid"
            link={
              IS_ASSESSMENT_REVAMP_ENABLED
                ? '/assessment'
                : '/assessment/litnum'
            }
            image="/icon/menu-asesmen.svg"
            onClick={() => {
              trackEventHomePage('CLICK_ASSESSMENT_PAGE')
            }}
          />

          <ProductItem
            data-testid="home-menu-kelas"
            title="Kelas"
            link="/learning-group"
            image="/icon/menu-kelas.svg"
            onClick={() => {
              trackEventHomePage('CLICK_CLASS_PRODUCT')
            }}
          />
        </div>
      </SectionGroup>

      <SectionGroup title="Inspirasi" className="!py-0">
        <div className="grid grid-cols-4 col-span-2 pt-2 gap-x-2 gap-y-4">
          <ProductItem
            data-testid="home-menu-video-inspirasi"
            title="Video Inspirasi"
            link="/video-inspirasi"
            image="/icon/menu-video-inspirasi.svg"
            onClick={() => {
              trackEventHomePage('CLICK_VIDEO_INSPIRATION')
            }}
          />

          <ProductItem
            data-testid="home-menu-bukti-karya"
            title="Bukti Karya"
            isExternalLink
            link={`${process.env.NEXT_PUBLIC_BASE_URL}/bukti-karya?from=HOMEPAGE`}
            image="/icon/menu-bukti-karya-saya.svg"
            onClick={() => {
              trackEventHomePage('CLICK_BUKTI_KARYA')
            }}
          />

          <ProductItem
            data-testid="home-menu-ide-praktik"
            title="Ide Praktik"
            link="/ide-praktik"
            image="/icon/ide-praktik.svg"
            onClick={() => {
              trackEventHomePage('CLICK_IDE_PRAKTIK')
            }}
          />
        </div>
      </SectionGroup>
    </div>
  )
}

export default Products
