type DeadlineState = 'withinDeadline' | 'exceedDeadline'

export const getDayToDeadlineState: (targetDate: string) => DeadlineState = (
  targetDate
) => {
  if (!targetDate) return 'withinDeadline'

  const deadlineDate = new Date(targetDate)
  const currentDate = new Date(Date.now())
  const diffTime = deadlineDate.getTime() - currentDate.getTime()

  if (diffTime < 0) return 'exceedDeadline'
  return 'withinDeadline'
}
