import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { isWebview } from 'utils/browser'
import { formatDate } from 'utils/datetime'
import { parseQuery } from 'utils/router'
import { useSatuEventTracker } from 'utils/satuEvent'

import type { IUjikomPeriodData } from 'types/content-platform'

interface TrackerProps {
  pemKomPeriod?: IUjikomPeriodData
  isPemKomEnabled?: boolean
}

interface TrackerFormProps {
  timeRemaining?: string
  formPage?: number
}

export interface TrackerTriggerProps {
  eventTrigger: 'PEMKOM_GUIDE_PAGE' | 'PEMKOM_FORM_PAGE'
}

type TrackerAttributeSource = 'WEB_HOMEPAGE' | 'HOMEPAGE' | 'EXTERNAL_LINK'

const TRACKER_EVENT_NAME = 'guru_kinerja_pemkom_v1'
const DEFAULT_TIMEOUT = 5000 // 5 seconds
export const TRACKER_PEMKOM_QUERY_SOURCE_KEY = 'source'
export const TRACKER_PEMKOM_QUERY_SOURCE_VALUE_LANDING = 'landing'
export const TRACKER_PEMKOM_QUERY_SOURCE_VALUE_HOMEPAGE = 'home'

export const useSETrackEvent = () => {
  const router = useRouter()
  const { SETrackEvent, SETransformAttrs } = useSatuEventTracker()

  const sourceParam: TrackerAttributeSource = useMemo(() => {
    const source = parseQuery<string>(
      router.query?.[TRACKER_PEMKOM_QUERY_SOURCE_KEY]
    )

    if (source === TRACKER_PEMKOM_QUERY_SOURCE_VALUE_LANDING)
      return 'WEB_HOMEPAGE'

    if (source === TRACKER_PEMKOM_QUERY_SOURCE_VALUE_HOMEPAGE) return 'HOMEPAGE'

    return 'EXTERNAL_LINK'

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  const mapPeriod = (pemKomPeriod: TrackerProps['pemKomPeriod']): string => {
    const periods = []
    if (pemKomPeriod?.openAt) {
      periods.push(formatDate(pemKomPeriod?.openAt, 'yyyyMMdd'))
    }

    if (pemKomPeriod?.exam?.endAt) {
      periods.push(formatDate(pemKomPeriod?.exam?.endAt, 'yyyyMMdd'))
    }

    return periods.join('-')
  }

  const trackHomeOpen = ({
    pemKomPeriod = null,
    isPemKomEnabled = false,
    hasCertificate = false,
  }: TrackerProps & { hasCertificate?: boolean }) => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'HOMEPAGE_PEMKOM',
        activity_type: 'OPEN_HOMEPAGE_PEMKOM',
        event_trigger: sourceParam,
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          is_pemkom_enabled: [!!isPemKomEnabled ? 1 : 0, 'int'],
          pemkom_period: mapPeriod(pemKomPeriod),
          certificate_flg: [hasCertificate ? 1 : 0, 'int'],
        }),
      },
    })
  }

  // To handle general button clicks for landing page /pemetaan-kompetensi
  const trackButtonClick = ({
    pemKomPeriod = null,
    activityType = null,
  }: TrackerProps & {
    activityType:
      | 'CLICK_PEMKOM_BUTTON'
      | 'CLICK_PEMKOM_RESUME_BUTTON'
      | 'CLICK_CHECK_CERTIFICATE'
  }) => {
    SETrackEvent({
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'HOMEPAGE_PEMKOM',
        activity_type: activityType,
        event_trigger: sourceParam,
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          pemkom_period: mapPeriod(pemKomPeriod),
        }),
      },
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
    })
  }

  const trackButtonClickSubmit = ({
    pemKomPeriod = null,
    timeRemaining = null,
    formPage = null,
  }: TrackerProps & TrackerFormProps) => {
    SETrackEvent({
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'PEMKOM_FORM_PAGE',
        activity_type: 'CLICK_SUBMIT_TEST',
        event_trigger: 'PEMKOM_FORM_PAGE',
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          pemkom_period: mapPeriod(pemKomPeriod),
          time_remaining: timeRemaining,
          form_page: formPage,
        }),
      },
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
    })
  }

  const trackButtonClickSubmitConfirm = ({
    pemKomPeriod = null,
    timeRemaining = null,
    formPage = null,
    profileData = null,
  }: TrackerProps &
    TrackerFormProps & {
      profileData?: any
    }) => {
    SETrackEvent({
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'PEMKOM_FORM_PAGE',
        activity_type: 'CLICK_CONFIRM_SUBMIT_TEST',
        event_trigger: 'PEMKOM_FORM_PAGE',
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          pemkom_period: mapPeriod(pemKomPeriod),
          time_remaining: timeRemaining,
          form_page: formPage,
          profile_data: profileData,
        }),
      },
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
    })
  }

  const trackButtonClickDoneConfirm = ({
    pemKomPeriod = null,
  }: TrackerProps) => {
    SETrackEvent({
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'PEMKOM_FORM_PAGE',
        activity_type: 'CLICK_DONE_TEST',
        event_trigger: 'PEMKOM_FORM_PAGE',
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          pemkom_period: mapPeriod(pemKomPeriod),
        }),
      },
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
    })
  }

  const trackButtonClickIntegrityPact = ({
    pemKomPeriod = null,
  }: TrackerProps) => {
    SETrackEvent({
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'PEMKOM_INTEGRITY_PACT_PAGE',
        activity_type: 'CLICK_PEMKOM_INTEGRITY_PACT_BUTTON',
        event_trigger: 'PEMKOM_INTEGRITY_PACT_PAGE',
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          pemkom_period: mapPeriod(pemKomPeriod),
        }),
      },
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
    })
  }

  const trackButtonClickStart = ({ pemKomPeriod = null }: TrackerProps) => {
    SETrackEvent({
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'PEMKOM_GUIDE_PAGE',
        activity_type: 'CLICK_PEMKOM_START_TEST_BUTTON',
        event_trigger: 'PEMKOM_GUIDE_PAGE',
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          pemkom_period: mapPeriod(pemKomPeriod),
        }),
      },
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
    })
  }

  const trackFormPageOpen = ({
    pemKomPeriod = null,
    timeRemaining = null,
    formPage = null,
    eventTrigger = null,
  }: TrackerProps & TrackerFormProps & TrackerTriggerProps) => {
    SETrackEvent({
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'PEMKOM_FORM_PAGE',
        activity_type: 'OPEN_FORM_PAGE',
        event_trigger: eventTrigger,
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          pemkom_period: mapPeriod(pemKomPeriod),
          time_remaining: timeRemaining,
          form_page: formPage,
        }),
      },
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
    })
  }

  // To handle general button clicks inside the form
  const trackButtonClickForm = ({
    pemKomPeriod = null,
    timeRemaining = null,
    formPage = null,
    activityType = null,
  }: TrackerProps &
    TrackerFormProps & {
      activityType:
        | 'CLICK_NEXT_PAGE_BUTTON'
        | 'CLICK_CLOSE_TEST_BUTTON'
        | 'CLICK_EXIT_TEST'
        | 'CLICK_CONTINUE_TEST'
        | 'CLICK_SAVE_DRAFT'
    }) => {
    SETrackEvent({
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'PEMKOM_FORM_PAGE',
        activity_type: activityType,
        event_trigger: 'PEMKOM_FORM_PAGE',
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          pemkom_period: mapPeriod(pemKomPeriod),
          time_remaining: timeRemaining,
          form_page: formPage,
        }),
      },
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
    })
  }

  // To handle general events under certificate page, /pemetaan-kompetensi/sertifikat
  const trackCertificatePage = ({
    pemKomPeriod = null,
    activityType = null,
  }: TrackerProps & {
    activityType:
      | 'OPEN_PEMKOM_CERTIFICATE_PAGE'
      | 'CLICK_DOWNLOAD_CERTIFICATE_BUTTON'
  }) => {
    SETrackEvent({
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'PEMKOM_SERTIFIKAT_PAGE',
        activity_type: activityType,
        event_trigger: sourceParam,
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          pemkom_period: mapPeriod(pemKomPeriod),
        }),
      },
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
    })
  }

  return {
    trackHomeOpen,
    trackButtonClick,
    trackButtonClickSubmit,
    trackButtonClickSubmitConfirm,
    trackButtonClickDoneConfirm,
    trackButtonClickIntegrityPact,
    trackButtonClickStart,
    trackFormPageOpen,
    trackButtonClickForm,
    trackCertificatePage,
  }
}
