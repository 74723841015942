import React from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'

import { Icon } from '@wartek-id/icon'

import SearchBoxComponent from 'components/SearchBox'
import SearchButtonIcon from 'app/Homepage/components/SearchButtonIcon'
import useSearchOnboarding from 'app/Homepage/hooks/useSearchOnboarding'
import { useContentSearchOnboarding } from 'stores/content-search'
import styles from '../pages/styles.module.css'

const SearchBox = () => {
  const router = useRouter()
  const contentSearchOnboardingStore = useContentSearchOnboarding()
  const { isEnabled, inputPlaceholder, isInputPlaceholderAnimated } =
    useSearchOnboarding()

  const clickHandler = React.useCallback(async () => {
    await router.push('/pencarian')

    if (!contentSearchOnboardingStore.isFinished) {
      contentSearchOnboardingStore.setFinished()
    }
  }, [contentSearchOnboardingStore, router])

  return (
    <div className={styles['search-box']}>
      <div className="flex items-center">
        <Icon className="!text-white mr-4">arrow_back</Icon>
        <div className="cursor-pointer w-full" onClick={clickHandler}>
          <SearchBoxComponent
            placeholder={inputPlaceholder}
            className={clsx(
              'cursor-pointer truncate',
              isInputPlaceholderAnimated && 'placeholder-fade-out'
            )}
            buttonIcon={
              isEnabled && !contentSearchOnboardingStore.isFinished
                ? SearchButtonIcon
                : null
            }
            readOnly
          />
        </div>
      </div>
    </div>
  )
}

export default SearchBox
