import React from 'react'
import { useRouter } from 'next/router'

// API Interfaces
import AndroidHandler from 'utils/androidHandler'
import { MILKvKeys, useGetKeyPairValue } from 'api/microlearning/useAPIKeyValue'
// Components
import CompatibilityDialog from 'app/Homepage/components/CompatibilityDialog'
import ProductSection from 'app/RuangGtkHomePage/components/ProductSection'
import ProductItem from 'app/RuangGtkHomePage/components/ProductItem'
import { TRACKER_REFKOM_QUERY_SOURCE_KEY } from 'app/PemetaanKompetensiPage/hooks/useSETrackEventRefkom'
import {
  IS_ASSESSMENT_REVAMP_ENABLED,
  IS_LMS_PMM_HOMEPAGE_ENTRY_ENABLED,
} from 'configs/toggle'
import { useUserGroupPpgDaljab } from 'utils/hooks/useUserGroup'
import { useMediaQueryHelper } from 'app/PemetaanKompetensiPage/hooks/useMediaQueryHelper'

interface IProductsProps {
  isKinerjaEntryPointEnabled: boolean
}

const Products = ({ isKinerjaEntryPointEnabled }: IProductsProps) => {
  const androidHandler = AndroidHandler()
  const router = useRouter()

  const { hasAccess: hasPpgDaljabAccess } = useUserGroupPpgDaljab()

  const { data: featureFlagData } = useGetKeyPairValue(MILKvKeys.FeatureFlag)

  const shouldCheckingRolePpgDaljab =
    featureFlagData?.data?.enableSertifikasiPendidikCheckGroupRole

  const shouldShowPpgDaljabEntryPoint = shouldCheckingRolePpgDaljab
    ? hasPpgDaljabAccess
    : featureFlagData?.data?.enableSertifikasiPendidik

  const shouldShowRefleksiKompetensiEntryPoint =
    featureFlagData?.data?.enableRefleksiKompetensiEntrypoint

  const shouldShowLMSEntryPoint =
    !androidHandler.enabled && IS_LMS_PMM_HOMEPAGE_ENTRY_ENABLED

  // Helpers
  const { isDesktop } = useMediaQueryHelper()

  const productList = [
    {
      group: 'Belajar Berkelanjutan',
      products: [
        {
          show: shouldShowLMSEntryPoint,
          title: 'Diklat',
          description: 'Pendidikan & pelatihan terbimbing menggunakan LMS',
          link: process.env.NEXT_PUBLIC_LMS_URL,
          image: '/icon/ruang-gtk/pr-lms.svg',
          isExternalLink: false,
        },
        {
          show: shouldShowPpgDaljabEntryPoint,
          title: 'Sertifikasi Pendidik',
          description: 'Pendidikan Profesi Guru (PPG) bagi Guru Tertentu',
          link: '/ppg-daljab',
          image: '/icon/ruang-gtk/pr-sertifikasi-pendidik.svg',
          isExternalLink: false,
        },
        {
          show: true,
          title: 'Pelatihan Mandiri',
          description: 'Materi untuk pengembangan kompetensi guru dan tendik',
          link: '/pelatihan-mandiri',
          image: '/icon/ruang-gtk/pr-pelatihan-mandiri.svg',
          isExternalLink: false,
        },
        {
          show: true,
          title: 'Komunitas',
          description: '144.000+ komunitas guru & tendik berbagi praktik baik',
          link: `/komunitas?source=HOMEPAGE`,
          image: '/icon/ruang-gtk/pr-komunitas.svg',
          isExternalLink: true,
        },
      ],
    },
    {
      group: 'Karir dan Kinerja',
      products: [
        {
          show: isKinerjaEntryPointEnabled,
          title: 'Pengelolaan Kinerja',
          description:
            'Perencanaan, pelaksanaan, dan penilaian kinerja Anda untuk pengembangan diri dan satdik',
          link: '/pengelolaan-kinerja',
          image: '/icon/ruang-gtk/pr-pengelolaan-kinerja.svg',
          isExternalLink: false,
        },
        {
          show: true,
          title: 'Seleksi Kepala Sekolah',
          description:
            'Info persyaratan, tahapan, & cek kualifikasi untuk seleksi',
          link: '/bcks/selection',
          image: '/icon/ruang-gtk/pr-seleksi-kepala-sekolah.svg',
          isExternalLink: false,
        },
        {
          show: shouldShowRefleksiKompetensiEntryPoint,
          title: 'Refleksi Kompetensi',
          description:
            'Rekomendasi pembelajaran yang disusun personal untuk Anda',
          link: `/refleksi-kompetensi?${TRACKER_REFKOM_QUERY_SOURCE_KEY}=pmm_home`,
          image: '/icon/ruang-gtk/pr-refleksi-kompetensi.svg',
          isExternalLink: false,
        },
      ],
    },
    {
      group: 'Inspirasi Pembelajaran',
      products: [
        {
          show: true,
          title: 'Perangkat Ajar',
          description:
            'Modul ajar & projek, buku teks, dan sejenisnya. Dikurasi Kemendikbudristek',
          link: '/perangkat-ajar',
          image: '/icon/ruang-gtk/pr-perangkat-ajar.svg',
          isExternalLink: false,
        },
        {
          show: true,
          title: 'CP/ATP',
          description:
            'Capaian Pembelajaran dan Alur Tujuan Pembelajaran sesuai mata pelajaran Anda',
          link: '/kurikulum/referensi-penerapan/capaian-pembelajaran/',
          image: '/icon/ruang-gtk/pr-cp-atp.svg',
          isExternalLink: false,
        },
        {
          show: true,
          title: 'Ide Praktik',
          description:
            'Artikel dan video terpilih tentang praktik pembelajaran terpusat pada murid',
          link: '/ide-praktik',
          image: '/icon/ruang-gtk/pr-ide-praktik.svg',
          isExternalLink: false,
        },
        {
          show: true,
          title: 'Bukti Karya',
          description: 'Rekam jejak karya, kinerja, dan kompetensi pendidik',
          link: `/bukti-karya?from=HOMEPAGE`,
          image: '/icon/ruang-gtk/pr-bukti-karya.svg',
          isExternalLink: true,
        },
        {
          show: true,
          title: 'Video Inspirasi',
          description: 'Kumpulan video terpilih berisi penerapan praktik baik',
          link: '/video-inspirasi',
          image: '/icon/ruang-gtk/pr-video-inspirasi.svg',
          isExternalLink: false,
        },
        {
          show: true,
          title: 'Asesmen Murid',
          description:
            'Paket soal dengan pemeriksaan otomatis dan analisis hasil',
          link: IS_ASSESSMENT_REVAMP_ENABLED
            ? '/assessment'
            : '/assessment/litnum',
          image: '/icon/ruang-gtk/pr-asesmen-murid.svg',
          isExternalLink: false,
        },
        {
          show: true,
          title: 'Kelas',
          description:
            'Informasi atau data kelompok murid berdasarkan hasil asesmen',
          link: '/learning-group',
          image: '',
          isExternalLink: false,
        },
      ],
    },
    {
      group: 'Dokumen Rujukan',
      products: [
        {
          show: true,
          title: 'Pengelolaan Pembelajaran',
          description: '',
          link: 'dokumen/kategori/oWx0b8DZ1a',
          image: '/icon/ruang-gtk/pr-dokumen-rujukan.svg',
          isExternalLink: false,
        },
        {
          show: true,
          title: 'Pengelolaan Kinerja',
          description: '',
          link: '/dokumen/kategori/Y7G6ArDWqJ',
          image: '/icon/ruang-gtk/pr-dokumen-rujukan.svg',
          isExternalLink: false,
        },
        {
          show: true,
          title: 'Peningkatan Kompetensi',
          description: '',
          link: '/dokumen/kategori/nxo92w0Eem',
          image: '/icon/ruang-gtk/pr-dokumen-rujukan.svg',
          isExternalLink: false,
        },
        {
          show: true,
          title: 'Pengelolaan Satuan Pendidikan',
          description: '',
          link: '/dokumen/kategori/o450Zr0mMG',
          image: '/icon/ruang-gtk/pr-dokumen-rujukan.svg',
          isExternalLink: false,
        },
      ],
    },
    {
      group: 'Lainnya',
      products: [
        {
          show: true,
          title: 'Pusat Bantuan',
          description: '',
          link: process.env.NEXT_PUBLIC_PUSAT_INFORMASI_BASE_URL + '/hc/en-us',
          image: '',
          isExternalLink: true,
        },
        {
          show: true,
          title: 'Kebijakan Privasi',
          description: '',
          link: '/policy',
          image: '',
          isExternalLink: false,
        },
        {
          show: true,
          title: 'Persyaratan Layanan',
          description: '',
          link: '/tns',
          image: '',
          isExternalLink: false,
        },
      ],
    },
  ]

  return (
    <div className="pb-20">
      {productList.map((section, index) => (
        <ProductSection key={index} title={section.group}>
          {section.products
            .filter((product) => product.show)
            .map(
              (product, idx) =>
                product.show && (
                  <>
                    {idx > 0 && <hr />}
                    {product.title === 'Pengelolaan Kinerja' ? (
                      <CompatibilityDialog
                        trigger={({ toggleDialog }) => (
                          <ProductItem
                            key={`${index}-${idx}`}
                            title={product.title}
                            description={product.description}
                            link={product.link}
                            isExternalLink={product.isExternalLink}
                            onClick={() => {
                              if (isDesktop) {
                                router.push('/pengelolaan-kinerja')
                              } else {
                                toggleDialog?.()
                              }
                            }}
                          />
                        )}
                        onContinue={() => {
                          router.push('/pengelolaan-kinerja')
                        }}
                      />
                    ) : (
                      <ProductItem
                        key={`${index}-${idx}`}
                        title={product.title}
                        description={product.description}
                        link={product.link}
                      />
                    )}
                  </>
                )
            )}
        </ProductSection>
      ))}
    </div>
  )
}

export default Products
