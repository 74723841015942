import { useMutationApi, useQueryApi } from 'utils/api'

import type { QueryApiConfig } from 'utils/api'
import type { AxiosError } from 'axios'

const API_SELECTIONS_KS_PATH = `${
  process.env.NEXT_PUBLIC_BCKS_USE_AMBASSADOR === 'true' ? '/guru' : ''
}/guru-ks/v1/ks/selections`

export type SelectionCandidateState =
  | 'invitation'
  | 'documentSubmission'
  | 'documentVerification'
  | 'documentVerificationFailed'
  | 'pairing'
  | 'rejectOffer'
  | 'reject'
  | 'failed'
  | 'finished'

export type SelectionVerificationState =
  | 'NOT_SUBMITTED'
  | 'NOT_STARTED'
  | 'ON_PROGRESS'
  | 'VALID'
  | 'INVALID'

export type SelectionDocument =
  | 'hasilPenilaianKinerja'
  | 'catatanKepolisian'
  | 'pengalamanManajerial'
  | 'kesehatan'
  | 'bebasHukuman'

export const allSelectionDocumentTypes: SelectionDocument[] = [
  'hasilPenilaianKinerja',
  'catatanKepolisian',
  'pengalamanManajerial',
  'kesehatan',
  'bebasHukuman',
]

export type SelectionDocumentState =
  | 'VALID'
  | 'INVALID'
  | 'UNVERIFIED'
  | 'NEED_REVISION'

export interface ResponseSuccessGetKSInvitationDocuments {
  hasilPenilaianKinerjaUrl: string | null
  hasilPenilaianKinerjaThumbnailUrl: string | null
  catatanKepolisianUrl: string | null
  catatanKepolisianThumbnailUrl: string | null
  pengalamanManajerialUrl: string | null
  pengalamanManajerialThumbnailUrl: string | null
  kesehatanUrl: string | null
  kesehatanThumbnailUrl: string | null
  bebasHukumanUrl: string | null
  bebasHukumanThumbnailUrl: string | null
}

export interface ResponseSuccessKSSelectionCandidate {
  deadlineDate: string
  endDate: string
  invitationFileUrl: string
  invitationText: string
  selectionCandidateState: SelectionCandidateState
  selectionId: string
  startDate: string
  totalCandidate: string
  schoolName: string
  schoolCity: string
}

export interface ResponseSuccessUploadSubmissionDocument {
  id: string
  fileType: string
}

export interface ResponseErrorUploadSubmissionDocument {
  message: string
}

export interface ResponseSuccessKSSelectionResult {
  isSelected: boolean
  skFileUrl: string
  candidate: {
    nip: string
    name: string
    dateOfBirth: string
  }
  school: {
    name: string
    city: string
  }
}

export interface RequestUploadSubmissionDocument {
  selectionId: string
  fileType:
    | 'HASIL_PENILAIAN_KINERJA'
    | 'SKCK'
    | 'SK_MANAJERIAL'
    | 'SKK'
    | 'SK_BEBAS_HUKUMAN'
  file: File
}

export function useMutateAPIGetKSSelectionCandidate() {
  return useMutationApi<ResponseSuccessKSSelectionCandidate, Error, {}>(
    `${API_SELECTIONS_KS_PATH}/candidate`,
    {
      axiosConfig: {
        method: 'GET',
      },
    }
  )
}

export function useQueryAPIGetKSSelectionCandidate() {
  return useQueryApi<ResponseSuccessKSSelectionCandidate>(
    `${API_SELECTIONS_KS_PATH}/candidate`,
    {
      queryConfig: {
        useErrorBoundary: false,
        cacheTime: 0,
      },
    }
  )
}

export function useMutateAPIAcceptKSInvitation(selectionId: string) {
  return useMutationApi<{ success: boolean }, Error, {}>(
    `${API_SELECTIONS_KS_PATH}/${selectionId}/invitations`,
    {
      axiosConfig: {
        method: 'POST',
      },
    }
  )
}

export function useMutateAPIUploadSubmission(selectionId: string) {
  return useMutationApi<
    ResponseSuccessUploadSubmissionDocument,
    AxiosError<ResponseErrorUploadSubmissionDocument>,
    FormData
  >(`${API_SELECTIONS_KS_PATH}/${selectionId}/submissions`, {
    axiosConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
}

export function useQueryAPIGetKSInvitationDocuments(selectionId: string) {
  return useQueryApi<ResponseSuccessGetKSInvitationDocuments>(
    `${API_SELECTIONS_KS_PATH}/${selectionId}/submissions`,
    {
      queryConfig: {
        useErrorBoundary: false,
        enabled: !!selectionId,
      },
    }
  )
}

export function useMutateAPIDeleteKSInvitationDocuments() {
  return useMutationApi<{ success: boolean }, Error, {}>(
    (data) =>
      `${API_SELECTIONS_KS_PATH}/${data.selectionId}/submissions?fileType=${data.fileType}`,
    {
      axiosConfig: {
        method: 'DELETE',
      },
    }
  )
}

export function useMutateAPIFinalizeSubmission(selectionId: string) {
  return useMutationApi<{ success: boolean }, Error, {}>(
    `${API_SELECTIONS_KS_PATH}/${selectionId}/submissions/finalized`,
    {
      axiosConfig: {
        method: 'POST',
      },
    }
  )
}

export function useQueryApiGetKSSelectionResult() {
  return useQueryApi<ResponseSuccessKSSelectionResult>(
    `${API_SELECTIONS_KS_PATH}/result`
  )
}

export type ResponseSuccessGetVerificationDocuments = {
  [key in SelectionDocument]: {
    url: string
    thumbnailUrl: string
    state: SelectionDocumentState
    reason: string
  }
} & {
  id: string
  state: SelectionVerificationState
}

export function useQueryAPIGetVerificationDocuments(
  selectionId: string,
  queryConfig: QueryApiConfig<ResponseSuccessGetVerificationDocuments>['queryConfig'] = {}
) {
  return useQueryApi<ResponseSuccessGetVerificationDocuments>(
    `${API_SELECTIONS_KS_PATH}/${selectionId}/verifications/me`,
    {
      queryConfig: {
        useErrorBoundary: false,
        enabled: !!selectionId,
        ...queryConfig,
      },
    }
  )
}
