import React from 'react'

import { KeyPairValueKeys, useGetKeyPairValue } from 'api/core/useAPIKeyValue'

const DEFAULT_PLACEHOLDER_CONFIG = {
  enabled: false,
  time: 0,
  label: ['Cari "modul ajar", "aksi nyata", dll'],
}

const useSearchOnboarding = () => {
  const interval = React.useRef<undefined | ReturnType<typeof setTimeout>>(
    undefined
  )
  const [inputPlaceholderIndex, setInputPlaceholderIndex] = React.useState(0)
  const [isInputPlaceholderAnimated, setInputPlaceholderAnimated] =
    React.useState(false)

  const {
    data: responseData,
    isSuccess,
    isError,
  } = useGetKeyPairValue<Record<string, any>>(
    KeyPairValueKeys.ContentSearchPlaceholders
  )

  const placeholderData = React.useMemo(() => {
    if (isError) {
      return DEFAULT_PLACEHOLDER_CONFIG
    }
    return responseData?.data
  }, [isError, responseData?.data])

  const inputPlaceholder = React.useMemo(
    () =>
      placeholderData?.label?.[inputPlaceholderIndex] ??
      DEFAULT_PLACEHOLDER_CONFIG.label[0],
    [placeholderData?.label, inputPlaceholderIndex]
  )

  const updatePlaceholderAnimation = () => {
    setTimeout(() => {
      setInputPlaceholderAnimated(true)
    }, placeholderData?.time - 100)
    setTimeout(() => {
      setInputPlaceholderAnimated(false)
    }, placeholderData?.time)
  }

  const updatePlaceholder = () => {
    setInputPlaceholderIndex(0)
    updatePlaceholderAnimation()

    interval.current =
      !interval.current &&
      setInterval(() => {
        setInputPlaceholderIndex(
          (prevIndex) => (prevIndex + 1) % placeholderData?.label.length
        )
        updatePlaceholderAnimation()
      }, placeholderData?.time)
  }

  React.useEffect(() => {
    if (!isSuccess || !placeholderData?.enabled) {
      return
    }
    updatePlaceholder()

    return () => {
      clearInterval(interval.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeholderData])

  return {
    isEnabled: placeholderData?.enabled,
    inputPlaceholder,
    isInputPlaceholderAnimated,
  }
}

export default useSearchOnboarding
