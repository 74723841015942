import { useQueryApi } from 'utils/api'

import type { IIKMEntryPoint } from 'types/content-platform'

export function useApiIIKMEntryPoint(enabled: boolean = false) {
  return useQueryApi<IIKMEntryPoint>(
    '/guru/content-platform/v1alpha1/ikm/registration-entry-point',
    {
      queryConfig: {
        useErrorBoundary: false,
        enabled,
      },
    }
  )
}
