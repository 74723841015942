import clsx from 'clsx'

import { Text } from '@wartek-id/text'

interface ISectionGroupProps extends React.HTMLProps<HTMLDivElement> {
  title: string
  children: React.ReactNode
}
const SectionGroup = ({
  title,
  children,
  className,
  ...props
}: ISectionGroupProps) => {
  return (
    <section {...props} className={clsx('py-4', className)}>
      <Text as="h2" variant="heading-md">
        {title}
      </Text>
      <div className="mt-3 grid grid-cols-2 gap-2">{children}</div>
    </section>
  )
}

export default SectionGroup
