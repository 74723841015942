import clsx from 'clsx'

import { Text } from '@wartek-id/text'

interface ISectionGroupProps extends React.HTMLProps<HTMLDivElement> {
  title: string
  children: React.ReactNode
}
const ProductSection = ({
  title,
  children,
  className,
  ...props
}: ISectionGroupProps) => {
  return (
    <section
      {...props}
      className={clsx('bg-gtk-blue mx-4 my-6 rounded-16', className)}
    >
      <Text as="h2" variant="subheading" className="p-4">
        {title}
      </Text>
      <div className="px-4 rounded-16 bg-white border border-gtk-basic-light">
        {children}
      </div>
    </section>
  )
}

export default ProductSection
