import NextLink from 'next/link'

import { Icon } from '@wartek-id/icon'
import { Text } from '@wartek-id/text-v1'

interface IProductLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  isExternalLink?: boolean
  link: string
  children: React.ReactNode
}

interface IProductItemProps extends React.HTMLAttributes<HTMLAnchorElement> {
  title: string
  description: string
  isExternalLink?: boolean
  link: string
}

const ProductLink = ({
  isExternalLink = false,
  children,
  link,
  ...props
}: IProductLinkProps) => {
  return isExternalLink ? (
    <a {...props} href={link}>
      {children}
    </a>
  ) : (
    <NextLink href={link} passHref>
      <a {...props}>{children}</a>
    </NextLink>
  )
}

const ProductItem = ({ title, description, ...props }: IProductItemProps) => {
  return (
    <ProductLink {...props} className="flex items-center py-4">
      <div className="w-full pr-0.5">
        <Text variant="body-bold" className="!text-gtk-blue-dark">
          {title}
        </Text>
        <Text variant="body-sm" className="mt-0.5">
          {description}
        </Text>
      </div>
      <Icon className="!text-gtk-blue-dark -mr-1.5">chevron_right</Icon>
    </ProductLink>
  )
}

export default ProductItem
