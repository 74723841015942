import React from 'react'
import NextImage from 'next/image'
import NextLink from 'next/link'

import { Badge } from '@wartek-id/badge'
import { Text } from '@wartek-id/text-v1'

import {
  useConversationEntryPointStore,
  useConversationOnboardingStore,
} from 'stores/conversation'
import ConversationCoachMark from './ConversationCoachMark'
import { useSETrackEvent } from 'app/ConversationPage/hooks/useSETrackEvent'

const ConversationEntryPoint = () => {
  const { isRendered, setRendered } = useConversationEntryPointStore()
  const { isOnboardingFinished } = useConversationOnboardingStore()
  const { SETrackConversationPage } = useSETrackEvent()

  const clickHandler = React.useCallback(() => {
    SETrackConversationPage('CLICK_GURU_AI_ENTRY', {
      screen_name: 'HOMEPAGE',
      event_trigger: 'HOMEPAGE',
      text_output: 'Anda bertanya, Asisten Guru menjawab',
      variant: 'control',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!isRendered) {
      SETrackConversationPage('VIEW_GURU_AI_ENTRYPOINT', {
        screen_name: 'HOMEPAGE',
        event_trigger: 'HOMEPAGE',
        text_output: 'Anda bertanya, Asisten Guru menjawab',
        variant: 'control',
      })
      setRendered()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      data-testid="conversation-entry-point"
      className="relative py-4 bg-gray-5"
    >
      <div id="coachmark-conversation-entry-point" className="px-4">
        <NextLink href="/asisten-guru" passHref>
          <a
            data-testid="conversation-entry-point-button"
            onClick={clickHandler}
          >
            <div className="relative flex items-center justify-between px-4 py-3 space-x-2 bg-white rounded-lg shadow-card">
              <div className="grow-0">
                <NextImage
                  src="/icon/icon-asisten-guru.png"
                  width="24px"
                  height="24px"
                />
              </div>
              <div className="grow">
                <Text variant="action">Diskusi dengan Asisten Guru</Text>
                <Text variant="helper" color="subdued">
                  Anda bertanya, Asisten Guru menjawab.
                </Text>
              </div>
              <div className="grow-0">
                <Badge className="!text-subdued !bg-surface-neutral-subdued !border-surface-neutral-subdued">
                  Beta
                </Badge>
              </div>
            </div>
          </a>
        </NextLink>
      </div>

      {isOnboardingFinished && <ConversationCoachMark />}
    </div>
  )
}

export default ConversationEntryPoint
