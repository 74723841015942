import React from 'react'
import NextHead from 'next/head'
import { useRouter } from 'next/router'

// General Components
import Header from 'app/Homepage/components/Header'
import NavigationBar from 'app/Homepage/components/NavigationBar'
// Features Components
import Banners from 'app/Homepage/components/Banners'
import { KSSelectionCard } from 'app/Homepage/components/KSSelectionCard'
import PendaftaranIKM from 'app/Homepage/components/PendaftaranIKM'
import Products from 'app/Homepage/components/Products'
import ContentCollection from 'app/Homepage/components/ContentCollection'
import DocumentCollectionEntryPoint from 'app/Homepage/components/DocumentCollectionEntryPoint'
import SearchBar from 'app/Homepage/components/SearchBar'
import ConversationEntryPointControl from 'app/Homepage/components/ConversationEntryPoint'
import ConversationEntryPointVariantA from 'app/Homepage/components/ConversationNewEntryPoint/ConversationEntryPointVariantA'
import ConversationEntryPointVariantB from 'app/Homepage/components/ConversationNewEntryPoint/ConversationEntryPointVariantB'
import ConversationOnboarding from 'app/Homepage/components/ConversationOnboarding'
import { IS_CONVERSATION_WITH_AI_NEW_ENTRY_POINT_ENABLED } from 'configs/toggle'
import { useConversationEntryPointStore } from 'stores/conversation'
import { useExperimentsContext } from 'utils/providers/useExperimentsContext'

const CONVERSATION_ENTRY_POINT = {
  variant_1: ConversationEntryPointVariantA,
  variant_2: ConversationEntryPointVariantB,
  control: ConversationEntryPointControl,
}

interface IHomepageProps {
  currentDateTimeServer: string
  isKinerjaEntryPointEnabled: boolean
  isGuruAIEligible: boolean
}
const Homepage = ({
  currentDateTimeServer,
  isKinerjaEntryPointEnabled,
  isGuruAIEligible,
}: IHomepageProps) => {
  const router = useRouter()

  // Guru AI Entry Point
  const { getExperimentByName } = useExperimentsContext()
  const experimentGuruAINewEntryPoint = getExperimentByName(
    'guru_ai_new_entrypoint'
  )
  const { setEntryPointVariant } = useConversationEntryPointStore()
  const conversationEntryPointVariant =
    (router?.query?.CONVERSATION_ENTRY_POINT_VARIANT as string) ??
    experimentGuruAINewEntryPoint?.treatment ??
    'control'
  const ConversationEntryPoint = IS_CONVERSATION_WITH_AI_NEW_ENTRY_POINT_ENABLED
    ? CONVERSATION_ENTRY_POINT?.[conversationEntryPointVariant] ?? 'control'
    : CONVERSATION_ENTRY_POINT.control

  const showConversationOnboarding = React.useMemo(() => {
    return isGuruAIEligible && conversationEntryPointVariant === 'control'
  }, [conversationEntryPointVariant, isGuruAIEligible])

  React.useEffect(() => {
    setEntryPointVariant(conversationEntryPointVariant)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationEntryPointVariant])

  React.useEffect(() => {
    router.prefetch('/pencarian')
  }, [router])

  return (
    <>
      {/* eslint-disable react/no-unknown-property */}
      <style jsx global>
        {`
          .entry-point-section:empty {
            padding: 0 !important;
          }
        `}
      </style>
      <NextHead>
        <title>Beranda - Ruang GTK</title>
        <meta
          name="description"
          content="Temukan beragam bahan ajar, buku teks, modul ajar, paket soal asesmen, serta program pelatihan untuk mendukung guru melakukan pembelajaran yang merdeka."
        />
      </NextHead>

      <Header
        data-testid="home-header-v2"
        showUserProfile={false}
        useHeaderV2
        className="!relative h-12 pt-2"
      />

      <div className="bg-gray-5 text-type-basic-dark scroll-smooth pb-14 min-h-[calc(100vh-56px)]">
        {/* Search Bar */}
        <SearchBar />

        {/* Homepage Banners */}
        <Banners className="pt-4 bg-white" />

        {/* Conversation Entry Point */}
        {isGuruAIEligible && <ConversationEntryPoint />}

        <div className="p-4 space-y-4 entry-point-section bg-gray-5">
          {/* Seleksi Kepala Sekolah Entry Point */}
          <KSSelectionCard />

          {/* IKM Entry Point */}
          <PendaftaranIKM />
        </div>

        <div className="relative py-3 mb-6 space-y-5 bg-white">
          {/* Products */}
          <Products
            currentDateTimeServer={currentDateTimeServer}
            isKinerjaEntryPointEnabled={isKinerjaEntryPointEnabled}
          />
        </div>

        {/* Content Collection */}
        <ContentCollection />

        {/* Document Collection */}
        <DocumentCollectionEntryPoint />
      </div>

      {/* Conversation Onboarding */}
      {showConversationOnboarding && <ConversationOnboarding />}

      <NavigationBar />
    </>
  )
}

export default Homepage
