const INTERNAL_BASE_URL = [
  'guru.staging.belajar.id',
  'guru.kemdikbud.go.id',
  'guru.kemendikdasmen.go.id',
]

const isInternalLink = (value) => {
  let url

  // check if value is starts with "/"
  if (value?.[0] === '/') {
    return true
  }

  // check if "value" is valid URL
  try {
    url = new URL(value)
  } catch (e) {
    return false
  }

  if (INTERNAL_BASE_URL.includes(url.host)) {
    return true
  }

  return false
}

export const getAnchorTarget = (value: string): string => {
  return isInternalLink(value) ? '_self' : '_blank'
}

export const getAnchorRelationship = (value: string): string => {
  return isInternalLink(value) ? '' : 'noreferrer'
}
