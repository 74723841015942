import { useQuery } from 'react-query'
import axios from 'axios'
import { captureException } from '@sentry/nextjs'

export enum KeyPairValueKeys {
  HomepageBanners = 'banner/cb',
  ContentCollection = 'playlists/ccv3',
  ContentSearchPlaceholders = 'search/placeholder',
  ContentSearchFilters = 'search/filter',
  ContentSearchWhitelists = 'search/whitelist',
  ConversationEntryPoint = 'asisten/entry',
}

const keyValueUrl = process.env.NEXT_PUBLIC_GURU_CORE_CDN

function fetchKeyValue<T>(key: string): Promise<{ data: T }> {
  return typeof key === 'undefined'
    ? Promise.reject(new Error('invalid Key'))
    : axios.get(`${keyValueUrl}/${key}.json`)
}

export function useGetKeyPairValue<T = any>(
  key: string,
  enabled = true,
  queryConfig = {}
) {
  return useQuery([keyValueUrl, key], () => fetchKeyValue<T>(key), {
    ...queryConfig,
    onError: (error) => captureException(error),
    enabled,
    staleTime: 60 * 60 * 1000, // 1 hour
    cacheTime: 60 * 60 * 1000, // 1 hour
    retry: 2,
  })
}
