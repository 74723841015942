import dynamic from 'next/dynamic'

import SearchBlueIcon from 'public/icon/search-blue.svg'

const SearchButtonDefaultIcon = () => (
  <SearchBlueIcon width="24px" height="24px" />
)

const SearchButtonIcon = dynamic(
  () =>
    import('app/Homepage/components/SearchButtonIcon/SearchButtonAnimatedIcon'),
  {
    loading: () => <SearchButtonDefaultIcon />,
    ssr: false,
  }
)

export default SearchButtonIcon
