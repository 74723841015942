import React from 'react'
import NextImage from 'next/image'
import NextLink from 'next/link'

import { Badge } from '@wartek-id/badge'
import { Icon } from '@wartek-id/icon-v2'
import { Text } from '@wartek-id/text-v1'

import { useSETrackEvent } from 'app/ConversationPage/hooks/useSETrackEvent'
import { useConversationEntryPointStore } from 'stores/conversation'

const ConversationEntryPointVariantB = () => {
  const { isRendered, setRendered } = useConversationEntryPointStore()
  const { SETrackConversationPage } = useSETrackEvent()

  const clickHandler = React.useCallback(() => {
    SETrackConversationPage('CLICK_GURU_AI_ENTRY', {
      screen_name: 'HOMEPAGE',
      event_trigger: 'HOMEPAGE',
      text_output: 'Mau tanya apa hari ini?',
      variant: 'variant_2',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!isRendered) {
      SETrackConversationPage('VIEW_GURU_AI_ENTRYPOINT', {
        screen_name: 'HOMEPAGE',
        event_trigger: 'HOMEPAGE',
        text_output: 'Mau tanya apa hari ini?',
        variant: 'variant_2',
      })
      setRendered()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      data-testid="conversation-entry-point"
      className="relative py-4 bg-white"
    >
      <div className="px-4">
        <NextLink href="/asisten-guru" passHref>
          <a
            data-testid="conversation-entry-point-button"
            onClick={clickHandler}
          >
            <div className="relative p-4 space-x-2 rounded-lg bg-surface-selected-default after:absolute after:content-[''] after:bg-[url('/images/asisten-guru/bg-stars.png')] after:bg-contain after:bg-no-repeat after:right-0 after:top-0 after:w-[70px] after:h-[55px]">
              <div>
                <div className="flex items-center mb-3 space-x-1">
                  <div>
                    <Text
                      variant="action"
                      className="!font-semibold !text-blue-90"
                    >
                      Asisten Guru
                    </Text>
                  </div>
                  <div>
                    <Badge className="!text-blue-60 !bg-[#CEE3FC] !border-[#CEE3FC]">
                      Beta
                    </Badge>
                  </div>
                </div>

                <div className="flex items-start mb-5 space-x-4">
                  <div>
                    <NextImage
                      src="/images/asisten-guru/avatar-rounded.png"
                      width="50px"
                      height="50px"
                      alt="Avatar Asisten Guru"
                    />
                  </div>
                  <div className="mt-4 relative px-4 py-3 rounded-full bg-blue-60 before:absolute before:content-[''] before:bg-[url('/images/asisten-guru/bubble-arrow.png')] before:bg-contain before:bg-no-repeat before:-left-1 before:top-0 before:w-[20px] before:h-[20px]">
                    <Text color="inverse" className="!leading-tight">
                      Mau tanya apa hari ini?
                    </Text>
                  </div>
                </div>

                <div className="flex items-center justify-between px-4 py-3 bg-white border rounded-md border-subdued">
                  <div>
                    <Text color="disabled">Klik untuk tanya AI sekarang</Text>
                  </div>
                  <Icon color="informational">arrow_forward</Icon>
                </div>
              </div>
            </div>
          </a>
        </NextLink>
      </div>
    </div>
  )
}

export default ConversationEntryPointVariantB
