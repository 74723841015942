import React from 'react'
import Image from 'next/image'

import { Text } from '@wartek-id/text'

const Hero = () => {
  return (
    <section className="px-4 pt-0 pb-10 -mt-11">
      <div className="rounded-16 bg-white p-4 flex flex-col items-start gap-2 shadow-hero">
        <Image
          src="/icon/ruang-gtk/logo.svg"
          width={52}
          height={29}
          alt="Ruang GTK"
        />
        <Text variant="heading-lg">
          Ruang Guru dan Tenaga Kependidikan (GTK)
        </Text>
        <Text variant="body-sm">
          Akses segala informasi dan kebutuhan untuk pendidik dan tenaga
          kependidikan
        </Text>
      </div>
    </section>
  )
}

export default Hero
