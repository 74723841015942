import { GATrackEvent } from 'utils/googleAnalytics'
import { useAuth } from 'utils/auth'

type TGATrackEventHomePage = {
  activityType: string
  eventName?: string
  eventTrigger?: string
  screenName?: string
}

export const useGATrackEvent = () => {
  const { session } = useAuth()

  const GATrackEventHomePage = ({
    activityType = '',
    eventName = 'GURU_CORE_APP',
    eventTrigger = 'HOMEPAGE',
    screenName = 'WEB_HOMEPAGE',
  }: TGATrackEventHomePage) => {
    GATrackEvent({
      eventName,
      sendDefaultParams: true,
      eventParams: {
        event_trigger: eventTrigger,
        referrer_screen_name: eventTrigger,
        screen_name: screenName,
        activity_type: activityType,
        is_login: !!session,
        page_location: window.location.origin,
        page_element: null,
      },
    })
  }

  return { GATrackEventHomePage }
}
