import clsx from 'clsx'
import React from 'react'

import { Button } from '@wartek-id/button-v1'
import { Icon } from '@wartek-id/icon'
import {
  Input,
  InputGroup,
  InputRightAddon,
  type InputProps,
} from '@wartek-id/input'

interface ISearchBoxProps extends InputProps {
  errorMessage?: string
  autoFocus?: boolean
  isClearable?: boolean
  isInvalid?: boolean
  onKeyDown?: (e: any) => any
  onClickButton?: () => void
  className?: string
  buttonIcon?: any
}
const SearchBox = React.forwardRef<HTMLInputElement, ISearchBoxProps>(
  (
    {
      autoFocus,
      errorMessage,
      isClearable,
      isInvalid,
      onKeyDown,
      onClickButton,
      className,
      buttonIcon,
      ...props
    },
    ref
  ) => {
    const ButtonIconComponent = buttonIcon ?? null
    return (
      <>
        {/* eslint-disable react/no-unknown-property */}
        <style jsx global>{`
          input[type='search'].search-box::-webkit-search-decoration,
          input[type='search'].search-box::-webkit-search-cancel-button,
          input[type='search'].search-box::-webkit-search-results-button,
          input[type='search'].search-box::-webkit-search-results-decoration {
            -webkit-appearance: none;
          }
        `}</style>

        <label htmlFor={props.name}>
          <InputGroup>
            <Input
              {...props}
              ref={ref}
              type="search"
              aria-label="Search"
              autoFocus={autoFocus}
              placeholder={props?.placeholder ?? 'Cari disini...'}
              className={clsx(
                'search-box !border-subdued !h-[46px]',
                isInvalid && '!border-red-60',
                className
              )}
              clearable={isClearable}
              errorMessage={errorMessage}
              isInvalid={isInvalid}
              onKeyDown={onKeyDown}
            />
            <InputRightAddon>
              <Button
                aria-label="Submit"
                className={clsx(
                  '!border !border-solid !border-subdued !outline-0',
                  isInvalid && '!border-red-60 !border-l-subdued !bg-[#fef4f4]'
                )}
                color="white"
                iconOnly
                size="lg"
                onClick={onClickButton}
              >
                {buttonIcon ? (
                  <ButtonIconComponent />
                ) : (
                  <Icon color={isInvalid ? 'critical' : 'informational'}>
                    search
                  </Icon>
                )}
              </Button>
            </InputRightAddon>
          </InputGroup>
        </label>
      </>
    )
  }
)

SearchBox.displayName = 'SearchBox'

export default SearchBox
