import React from 'react'
import NextImage from 'next/image'
import NextLink from 'next/link'

import { Button } from '@wartek-id/button-v1'
import { Text } from '@wartek-id/text'

import { KeyPairValueKeys, useGetKeyPairValue } from 'api/core/useAPIKeyValue'
import SectionGroup from 'app/Homepage/components/SectionGroup'
import { useSETrackEvent } from 'app/Homepage/hooks/useSETrackEvent'
import { getAnchorTarget, getAnchorRelationship } from 'utils/link'

import type {
  TContentCollection,
  TContentCollectionData,
} from 'types/content-collection'

interface IContentCollectionCardProps
  extends React.HTMLAttributes<HTMLAnchorElement> {
  data: TContentCollectionData
}
const ContentCollectionCard = ({
  data,
  ...props
}: IContentCollectionCardProps) => {
  const recommendationText = Boolean(data?.image) ? (
    <div>Rekomendasi dari:</div>
  ) : (
    'Rekomendasi '
  )
  const { SETrackCCV3ClickItem } = useSETrackEvent()

  return (
    <NextLink href={data.url_main} passHref>
      <a
        {...props}
        target={getAnchorTarget(data.url_main)}
        rel={getAnchorRelationship(data.url_main)}
        className="grid grid-cols-12 gap-2 mb-3 bg-white rounded-md shadow-card"
        onClick={() => {
          SETrackCCV3ClickItem(data.url_main, data.title)
        }}
      >
        {Boolean(data?.thumbnail) && (
          <div className="relative col-span-3">
            <NextImage
              src={data.thumbnail}
              alt={data.title}
              layout="fill"
              className="object-cover object-center rounded-tl-md rounded-bl-md"
            />
          </div>
        )}
        <div className="col-span-9 p-3">
          <div className="mb-2">
            <Text variant="action" className="break-words line-clamp-2">
              {data.title}
            </Text>
          </div>

          <div className="flex items-start space-x-2">
            {Boolean(data?.image) ? (
              <NextImage
                src={data.image}
                alt="Profile image"
                width={28}
                height={28}
                className="rounded-full"
              />
            ) : null}

            <div>
              <Text variant="helper" className="break-words line-clamp-2">
                {recommendationText} {data.name}
              </Text>
              {Boolean(data.group) && (
                <Text variant="helper" className="break-words line-clamp-2">
                  {data.group}
                </Text>
              )}
            </div>
          </div>
        </div>
      </a>
    </NextLink>
  )
}

const ContentCollection = () => {
  const { SETrackCCV3Loaded, SETrackCCV3ClickMoreButton } = useSETrackEvent()

  const { data, isSuccess } = useGetKeyPairValue<TContentCollection>(
    KeyPairValueKeys.ContentCollection
  )
  const contentCollectionData = React.useMemo<
    TContentCollection | Record<string, never>
  >(() => (isSuccess ? data?.data : {}), [data?.data, isSuccess])
  const hasContentCollection = React.useMemo(
    () => (contentCollectionData?.data ?? []).length > 0,
    [contentCollectionData?.data]
  )

  React.useEffect(() => {
    if (hasContentCollection) {
      const urls = (contentCollectionData?.data ?? [])
        .map(({ url_main: urlMain }) => urlMain)
        .join(' | ')
      SETrackCCV3Loaded(urls)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasContentCollection])

  if (!hasContentCollection) {
    return null
  }

  return (
    <div className="px-4 mb-6">
      <SectionGroup
        data-testid="home-content-collection-v3-container"
        title={contentCollectionData?.header}
        className="!py-0"
      >
        <div className="col-span-2">
          {(contentCollectionData?.data ?? []).map((item, index) => (
            <ContentCollectionCard
              data-testid="home-content-collection-v3-item"
              key={`content-collection-v3-item-${index}`}
              data={item}
            />
          ))}

          {Boolean(contentCollectionData?.url_list) && (
            <div className="pt-1">
              <NextLink href={contentCollectionData?.url_list} passHref>
                <Button
                  data-testid="home-content-collection-v3-list-button"
                  as="a"
                  variant="solid"
                  color="white"
                  target={getAnchorTarget(contentCollectionData?.url_list)}
                  rel={getAnchorRelationship(contentCollectionData?.url_list)}
                  onClick={() =>
                    SETrackCCV3ClickMoreButton(contentCollectionData?.url_list)
                  }
                >
                  Lihat lebih banyak
                </Button>
              </NextLink>
            </div>
          )}
        </div>
      </SectionGroup>
    </div>
  )
}

export default ContentCollection
