import React from 'react'
import NextImage from 'next/image'
import clsx from 'clsx'

import { Carousel } from '@wartek-id/carousel-v1'
import { Text } from '@wartek-id/text'

import {
  IS_HOME_BANNERS_AUTOPLAY_ENABLED,
  IS_HOME_BANNERS_V2_ENABLED,
} from 'configs/toggle'
import { useAuth } from 'utils/auth'
import { getAnchorTarget } from 'utils/link'
import { useGetKeyPairValue, KeyPairValueKeys } from 'api/core/useAPIKeyValue'
import { useAPIGetHomeBanners } from 'api/home-banners/useAPIHomeBanners'
import { useSETrackEvent } from 'app/Homepage/hooks/useSETrackEvent'
import { filterBanners } from 'app/Homepage/utils/banners'
import { getUserDisplayName } from 'app/Homepage/utils/user'

import type {
  TBannersResponse,
  TBannersLinkFallbackProps,
  TSETrackHomeBannersActivities,
} from 'types/home-banner'

const CAROUSEL_AUTOPLAY_SPEED = 3_000

const LinkFallback = ({ children, link }: TBannersLinkFallbackProps) =>
  Boolean(link) ? (
    <a href={link} target={getAnchorTarget(link)}>
      {children}
    </a>
  ) : (
    <div>{children}</div>
  )

interface IBannersProps extends React.HTMLProps<HTMLDivElement> {
  className?: string
  showUserName?: boolean
}
const Banners = ({ className, showUserName }: IBannersProps) => {
  // Prevent click event triggered when swiping Carousel component
  const isSwiping = React.useRef<boolean>(false)

  const { session } = useAuth()
  const userDisplayName = getUserDisplayName(session?.user?.name)
  const { SETrackBanners } = useSETrackEvent()

  const { data: response, isError } = useGetKeyPairValue<TBannersResponse>(
    KeyPairValueKeys.HomepageBanners,
    !IS_HOME_BANNERS_V2_ENABLED
  )
  const { data: responseV2, isError: isErrorV2 } = useAPIGetHomeBanners({
    enabled: IS_HOME_BANNERS_V2_ENABLED,
  })

  // Filters displayed Banners
  const banners = React.useMemo(() => {
    if (IS_HOME_BANNERS_V2_ENABLED) {
      return responseV2?.data ?? []
    }
    return filterBanners(response?.data?.banners ?? [])
  }, [response?.data?.banners, responseV2?.data])
  const hasError = IS_HOME_BANNERS_V2_ENABLED ? isErrorV2 : isError

  const showNavigation = React.useMemo(
    () => banners.length > 1,
    [banners.length]
  )

  const handleSETrackBanners = (
    activityType: TSETrackHomeBannersActivities,
    additionalParams?: Record<string, any>
  ): void => {
    SETrackBanners(activityType, {
      topic_id: Object.values(banners)
        .map((banner) => banner?.id ?? banner?.link)
        .join(','),
      num_components: (banners ?? []).length,
      ...additionalParams,
    })
  }

  if (hasError || (banners ?? []).length === 0) return null

  return (
    <section className={clsx(className, 'pb-2')}>
      {showUserName && (
        <div className="p-4 pt-5">
          <Text variant="heading-lg" className="truncate !font-semibold">
            Halo, {userDisplayName}
          </Text>
        </div>
      )}

      <Carousel
        {...(IS_HOME_BANNERS_AUTOPLAY_ENABLED
          ? {
              autoplay: true,
              autoplaySpeed: CAROUSEL_AUTOPLAY_SPEED,
              infinite: true,
            }
          : { infinite: false })}
        centerMode
        centerPadding="18px 0 16px"
        onInit={() => {
          handleSETrackBanners('LOAD_HOMEPAGE_CAROUSEL_BANNER')
        }}
        onSwipe={() => {
          isSwiping.current = true
        }}
        beforeChange={(oldIndex, newIndex) => {
          if (oldIndex === newIndex) return

          const isSwipeToFirst =
            IS_HOME_BANNERS_AUTOPLAY_ENABLED &&
            oldIndex === banners.length - 1 &&
            newIndex === 0
          const isSwipeToLast =
            IS_HOME_BANNERS_AUTOPLAY_ENABLED &&
            oldIndex === 0 &&
            newIndex === banners.length - 1
          const direction =
            (oldIndex < newIndex || isSwipeToFirst) && !isSwipeToLast
              ? 'RIGHT'
              : 'LEFT'
          handleSETrackBanners(`SWIPE_${direction}_HOMEPAGE_CAROUSEL_BANNER`, {
            topic_id: banners?.[newIndex]?.id ?? banners?.[newIndex]?.link,
            page_element: newIndex + 1,
            event_trigger: isSwiping.current ? 'HOMEPAGE' : 'AUTO',
          })
        }}
        afterChange={() => {
          isSwiping.current = false
        }}
      >
        {banners.map((banner, index) => {
          return (
            <div key={`homepage-banner-${index}`}>
              <div
                className={clsx(
                  'mr-3',
                  showNavigation && 'mb-14',
                  !showNavigation && 'mb-6'
                )}
                onClick={() => {
                  if (isSwiping.current || !Boolean(banner?.link)) return

                  handleSETrackBanners('CLICK_HOMEPAGE_CAROUSEL_BANNER', {
                    topic_id: banner?.id ?? banner?.link,
                    page_element: index + 1,
                  })
                }}
              >
                <LinkFallback link={banner?.link}>
                  <NextImage
                    src={
                      IS_HOME_BANNERS_V2_ENABLED
                        ? banner.imageUrl
                        : banner.image
                    }
                    width="800px"
                    height="450px"
                    layout="responsive"
                    className="object-cover rounded-[4px]"
                  />
                </LinkFallback>
              </div>
            </div>
          )
        })}
      </Carousel>
    </section>
  )
}

export default Banners
