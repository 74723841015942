import type { SelectionCandidateState } from 'api/bcks/useAPIBCKSInvitation'

export const URL_HELPDESK_OLD =
  'https://pusatinformasi.pengangkatan-ksps.kemdikbud.go.id/hc/en-gb'

export const URL_HELPDESK =
  'https://pusatinformasi.pengangkatan-ksps.kemendikdasmen.go.id/hc/en-us'

export const LABEL_NOT_AVAILABLE = 'Belum ada data'

export const SELECTION_STATES_FINAL_PAGE: SelectionCandidateState[] = [
  'documentVerification',
  'documentVerificationFailed',
  'pairing',
  'rejectOffer',
  'reject',
  'failed',
  'finished',
]

export const SELECTION_STATES_ANNOUNCEMENT: SelectionCandidateState[] = [
  'finished',
]
