import React from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'

import SearchBoxComponent from 'components/SearchBox'
import useSearchOnboarding from 'app/Homepage/hooks/useSearchOnboarding'
import SearchButtonIcon from 'app/Homepage/components/SearchButtonIcon'
import { useSETrackEvent } from 'app/Homepage/hooks/useSETrackEvent'
import {
  useContentSearchOnboarding,
  useContentSearchTracker,
} from 'stores/content-search'

const SearchBar = () => {
  const router = useRouter()
  const contentSearchOnboardingStore = useContentSearchOnboarding()
  const contentSearchTrackerStore = useContentSearchTracker()
  const { isEnabled, inputPlaceholder, isInputPlaceholderAnimated } =
    useSearchOnboarding()
  const { SETrackClickSearchBar } = useSETrackEvent()

  const clickHandler = React.useCallback(async () => {
    contentSearchTrackerStore.setSource('HOMEPAGE_SEARCH_BAR')
    SETrackClickSearchBar()
    await router.push('/pencarian')

    if (!contentSearchOnboardingStore.isFinished) {
      contentSearchOnboardingStore.setFinished()
    }
  }, [
    SETrackClickSearchBar,
    contentSearchOnboardingStore,
    contentSearchTrackerStore,
    router,
  ])

  return (
    <>
      {/* eslint-disable react/no-unknown-property */}
      <style jsx global>
        {`
          input.search-box::-webkit-input-placeholder {
            transition: opacity 0.2s ease-in !important;
            opacity: 100;
          }
          input.search-box.placeholder-fade-out::-webkit-input-placeholder {
            transition: opacity 0.2s ease-out !important;
            opacity: 0;
          }
        `}
      </style>
      <div className="sticky top-0 z-10 px-4 pt-2 pb-3 bg-expressive-dark-blue">
        <div className="cursor-pointer" onClick={clickHandler}>
          <SearchBoxComponent
            data-testid="homepage-search-bar-input"
            placeholder={inputPlaceholder}
            className={clsx(
              'cursor-pointer truncate',
              isInputPlaceholderAnimated && 'placeholder-fade-out'
            )}
            buttonIcon={
              isEnabled && !contentSearchOnboardingStore.isFinished
                ? SearchButtonIcon
                : null
            }
            readOnly
          />
        </div>
      </div>
    </>
  )
}

export default SearchBar
