import { useState } from 'react'
import Image from 'next/image'

import type { DialogProps } from '@wartek-id/dialog-v1'
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogCloseButton,
} from '@wartek-id/dialog-v1'
import { Button } from '@wartek-id/button-v1'
import { Text } from '@wartek-id/text-v1'

import type { FC } from 'react'

type TTrigger = ({
  toggleDialog,
}: {
  toggleDialog: () => void
}) => React.ReactNode

interface CompatibilityDialogProps
  extends Omit<DialogProps, 'children' | 'isOpen'> {
  trigger: TTrigger
  onContinue?: () => void
  onClose?: () => void
}

/**
 * Compatibility Dialog, display compatibility dialog before continue as the target page doesn't support mobile view
 *
 * @example
 * <CompatibilityDialog
 *   trigger={({ toggleDialog }) => {
 *     return (
 *       <Button
 *         type="button"
 *         onClick={(e) => {
 *           e.preventDefault() // Prevent default handler
 *           toggleDialog?.() // Open dialog
 *         }}
 *       />
 *     )
 *   }}
 *   onContinue={() => {
 *     window.location.href = 'https://google.com' // Where this should be redirected
 *   }}
 * />
 */
const CompatibilityDialog: FC<CompatibilityDialogProps> = ({
  trigger,
  onContinue,
  onClose,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      {trigger?.({
        toggleDialog: () => setIsOpen(!isOpen),
      })}

      <Dialog
        closeOnOverlayClick={false}
        size="sm"
        {...props}
        isOpen={isOpen}
        onClose={onClose}
      >
        <DialogOverlay />
        <DialogContent className="!rounded-lg !w-80">
          <DialogCloseButton onClick={() => setIsOpen(false)} />
          <section className="p-4 space-y-4">
            <div className="text-center">
              <Image
                layout="fixed"
                src="/images/img-typing-monitor.png"
                width={120}
                height={120}
                alt=""
              />
            </div>
            <div className="tw-space-y-1">
              <Text variant="heading-sm">Buka sistem di komputer/laptop</Text>
              <Text variant="body">
                Tampilan sistem paling baik dilihat lewat komputer atau laptop.
              </Text>
            </div>

            <div className="flex gap-5 mt-5">
              <Button
                fullWidth
                size="lg"
                onClick={() => {
                  setIsOpen(false)
                  onContinue?.()
                }}
              >
                Tetap di sini
              </Button>
            </div>
          </section>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CompatibilityDialog
