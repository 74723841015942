// Toggles
import { useTogglesStore } from 'stores/toggles'
// API Interfaces
import { useApiIIKMEntryPoint } from 'api/content-platform/useAPIContentPlatform'
// Components
import PendaftaranIKMCard from 'app/Homepage/components/PendaftaranIKMCard'
import SectionGroup from 'app/Homepage/components/SectionGroup'
// Trackers
import { useSETrackEvent } from 'app/Homepage/hooks/useSETrackEvent'

const PendaftaranIKM = () => {
  // IKM Entry Point
  const isIKMRegistrationHomeEnabled = useTogglesStore(
    (state) => state.toggles?.['ikm-registration-home-enabled']
  )
  const { status: IIKMEntryStatus, data: IIKMEntryPointData } =
    useApiIIKMEntryPoint(isIKMRegistrationHomeEnabled)
  const showPendaftaranIKM =
    IIKMEntryStatus === 'success' &&
    IIKMEntryPointData?.isShowEntryPoint &&
    isIKMRegistrationHomeEnabled

  // Trackers
  const { SETrackPendaftaranIKMLoaded, SETrackPendaftaranIKMClicked } =
    useSETrackEvent()

  return (
    showPendaftaranIKM && (
      <SectionGroup title="Kegiatan Terkini" className="!py-0">
        <PendaftaranIKMCard
          title={IIKMEntryPointData?.title}
          subtitle={IIKMEntryPointData?.subtitle}
          buttonTitle={IIKMEntryPointData?.buttonTitle}
          imageUrl={IIKMEntryPointData?.imageUrl}
          loadTracker={SETrackPendaftaranIKMLoaded}
          clickTracker={SETrackPendaftaranIKMClicked}
          redirectionUrl={IIKMEntryPointData?.redirectionUrl}
          activityEvent={IIKMEntryPointData?.activityEvent}
        />
      </SectionGroup>
    )
  )
}

export default PendaftaranIKM
