import { useSatuEventTracker } from 'utils/satuEvent'
import { getSession } from 'utils/auth'

const SATU_EVENT_NAME = 'guru_skp_fe_user_action_v1'

type ScreenName =
  | 'WEB_HOMEPAGE'
  | 'LANDING_PAGE'
  | 'KINERJA_HOMEPAGE'
  | 'KINERJA_PLANNING_PRAKTIK_PEMBELAJARAN_PAGE'
  | 'KINERJA_PLANNING_PENGEMBANGAN_KOMPETENSI_PAGE'
  | 'KINERJA_PLANNING_TUGAS_TAMBAHAN_PAGE'
  | 'KINERJA_PLANNING_PENGAJUAN_POP_UP'
  | 'KINERJA_LIST_OF_TEACHERS_PLANNING_PAGE'
  | 'KINERJA_APPROVE_PLANNING_POP_UP'
  | 'KINERJA_PLANNING_PERILAKU_KERJA_PAGE'
  | 'KINERJA_PLANNING_AGREEMENT_POP_UP'

interface TrackerParams {
  event_name: string
  screen_name: ScreenName
  kinerja_planning_id?: string
  kinerja_planning_status?: string
}

const useSKPTrackEvent = () => {
  const { SETrackEvent } = useSatuEventTracker()
  const session = getSession()

  const trackEvent = (params: TrackerParams) => {
    SETrackEvent({
      eventName: SATU_EVENT_NAME,
      eventParams: {
        ...params,
        event_trigger: params.screen_name,
        event_state: '',
        event_trace_id: '',
        user_id: session?.user?.id ?? '-',
      },
      sendDefaultParams: true,
      // we manually send the user data above
      // to handle case where the user is not logged in
      sendUserData: false,
      sendAttributes: false,
    })
  }

  return {
    trackEvent,
  }
}

export default useSKPTrackEvent
