import React from 'react'

import Homepage from 'app/Homepage/pages/Home'
import RuangGtkHomePage from 'app/RuangGtkHomePage/pages/Home'
import { checkDateTimeServer } from 'app/PemetaanKompetensiPage/utils'
import { IS_CONVERSATION_WITH_AI_ENABLED } from 'configs/toggle'
import { isToggleEnabled } from 'configs/toggle-feature'
import { getDeviceId } from 'utils/deviceId'
import { useExperimentsContext } from 'utils/providers/useExperimentsContext'
import useExperiment from 'app/ConversationPage/hooks/useExperiment'
import { IS_CONVERSATION_WITH_AI_NEW_ENTRY_POINT_ENABLED } from 'configs/toggle'

export async function getServerSideProps(ctx) {
  const currentDateTimeServer = await checkDateTimeServer()
  const isKinerjaEntryPointEnabled = await isToggleEnabled('kinerja-enabled')
  const isRuangGtkEnabled = await isToggleEnabled('ruang-gtk-enabled')

  return {
    props: {
      appDeviceID: getDeviceId(ctx),
      currentDateTimeServer,
      isKinerjaEntryPointEnabled,
      isRuangGtkEnabled,
    },
  }
}

export const App = ({
  appDeviceID,
  currentDateTimeServer,
  isKinerjaEntryPointEnabled = false,
  isRuangGtkEnabled = false,
}) => {
  const [isGuruAIExperimentVariantEnabled, setGuruAIExperimentVariantEnabled] =
    React.useState<boolean>(false)
  const { getExperimentStatus: getGuruAIExperimentStatus } =
    useExperiment(appDeviceID)

  const { getExperimentByName, isFetchExperimentsSuccess } =
    useExperimentsContext()
  const experimentGuruAIRollout = getExperimentByName('guru_ai_rollout')

  const isGuruAIEligible = React.useMemo(
    () =>
      (IS_CONVERSATION_WITH_AI_ENABLED &&
        isGuruAIExperimentVariantEnabled) as boolean,
    [isGuruAIExperimentVariantEnabled]
  )

  const fetchExperiment = React.useCallback(async () => {
    if (IS_CONVERSATION_WITH_AI_NEW_ENTRY_POINT_ENABLED) {
      if (isFetchExperimentsSuccess) {
        setGuruAIExperimentVariantEnabled(
          experimentGuruAIRollout?.treatment === 'variant'
        )
      }
    } else {
      try {
        const isVariantEnabled = await getGuruAIExperimentStatus()
        setGuruAIExperimentVariantEnabled(isVariantEnabled)
      } catch (e) {
        setGuruAIExperimentVariantEnabled(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experimentGuruAIRollout?.treatment, isFetchExperimentsSuccess])

  React.useEffect(() => {
    fetchExperiment()
  }, [fetchExperiment])

  if (isRuangGtkEnabled) {
    return (
      <RuangGtkHomePage
        isKinerjaEntryPointEnabled={isKinerjaEntryPointEnabled}
      />
    )
  }

  return (
    <Homepage
      currentDateTimeServer={currentDateTimeServer}
      isKinerjaEntryPointEnabled={isKinerjaEntryPointEnabled}
      isGuruAIEligible={isGuruAIEligible}
    />
  )
}

App.layoutOpts = {
  hasHeader: false,
}

export default App
