import React from 'react'
import { CardinalOrientation } from 'walktour'

import { Text } from '@wartek-id/text-v1'

import { CoachMark } from 'components/CoachMark'
import { useConversationOnboardingStore } from 'stores/conversation'
import { useSETrackEvent } from 'app/ConversationPage/hooks/useSETrackEvent'

const ONBOARDING_STEPS = [
  {
    selector: '#coachmark-conversation-entry-point',
    description: null,
    customDescriptionRenderer: () => (
      <Text color="inverse">Buka Asisten Guru di sini, ya</Text>
    ),
    disableMaskInteraction: true,
  },
]

const ConversationCoachMark = () => {
  const { isOnboardingFinished, isCoachMarkFinished, setCoachMarkFinished } =
    useConversationOnboardingStore()
  const [isCoachMarkDisplayed, setCoachMarkDisplayed] =
    React.useState<boolean>(false)
  const { SETrackConversationPage } = useSETrackEvent()

  const finishHandler = React.useCallback(() => {
    setCoachMarkFinished()
    SETrackConversationPage('CLICK_GURU_AI_ONBOARDING_READY', {
      screen_name: 'HOMEPAGE',
      event_trigger: 'HOMEPAGE',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!isCoachMarkDisplayed && isOnboardingFinished && !isCoachMarkFinished) {
      setTimeout(() => {
        setCoachMarkDisplayed(true)
        setCoachMarkFinished()
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCoachMarkDisplayed, isCoachMarkFinished, isOnboardingFinished])

  return (
    <CoachMark
      orientationPreferences={[CardinalOrientation.SOUTH]}
      disableClose={true}
      disableCloseOnClick={false}
      disablePrev={true}
      isOpen={isCoachMarkDisplayed}
      rootSelector="html"
      steps={ONBOARDING_STEPS}
      onFinish={finishHandler}
      tooltipConfigs={{
        closeLabel: 'Siap',
      }}
      maskRadius={8}
      maskPadding={10}
    />
  )
}

export default ConversationCoachMark
