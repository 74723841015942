import { useQuery } from 'react-query'
import axios from 'axios'
import { captureException } from '@sentry/nextjs'

export enum MILKvKeys {
  FeatureFlag = 'feature_flag',
  WebinarEntries = 'webinar_entries',
  PendaftaranKomunitas = 'ikm_info_page',
  IKMDocuments = 'ikm_document',
  IKMFilter = 'ikm_community_filter',
  IKMCommunityRecommendation = 'ikm_recommended_community',
  RKMMContributionBanner = 'rkmm_kontribusi_banner',
  LearningSeries = 'learning_series',
  TopicSpeed = 'topic_speed',
  DisabledMateriVideo = 'disabled_materi_video',
  FAQSertifikatMandiri = 'faq_sertifikat_mandiri',
  TopikReferensiTab = 'topik_referensi_tab',
  TopicSurveyBanner = 'topic_survey',
  KMDisclaimer = 'km_disclaimer',
}

let keyValueUrl = !!process.env.NEXT_PUBLIC_BELAJAR_CDN
  ? process.env.NEXT_PUBLIC_BELAJAR_CDN + '/kv'
  : 'https://mil-kv.guru.belajar.id/kv'

function fetchKeyValue<T>(key: string): Promise<{ data: T }> {
  return typeof key === 'undefined'
    ? Promise.reject(new Error('invalid Key'))
    : axios.get(`${keyValueUrl}/${key}.json`)
}

export function useGetKeyPairValue<T = any>(
  key: string,
  enabled: boolean = true,
  queryConfig = {}
) {
  return useQuery([keyValueUrl, key], () => fetchKeyValue<T>(key), {
    ...queryConfig,
    onError: (error) => captureException(error),
    enabled,
  })
}
