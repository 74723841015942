import type { TBanner } from 'types/home-banner'

export const filterBanners = (banners: TBanner[]): TBanner[] => {
  const currentDate = new Date()

  return (
    (banners ?? [])

      .filter((banner) => {
        const flag = banner?.flag ?? []
        const image = banner?.image ?? ''

        // Check if "image" empty
        if (!Boolean(image)) {
          return false
        }

        // Display only item with "login" flag or with empty flag
        if (!(flag ?? []).includes('login')) {
          return false
        }

        // Display according to "startDate" & "endDate"
        const startDate = banner?.startDate
          ? new Date(`${banner?.startDate} 00:00:00`)
          : null
        const endDate = banner?.endDate
          ? new Date(`${banner?.endDate} 23:59:59`)
          : null
        if (
          Boolean(banner?.startDate) &&
          Boolean(banner?.endDate) &&
          (startDate > currentDate || endDate < currentDate)
        ) {
          return false
        }

        return banner
      })

      // Sort by "sorting" field
      .sort((a, b) => {
        const sortingA = isNaN(a?.sorting) ? 0 : a.sorting
        const sortingB = isNaN(b?.sorting) ? 0 : b.sorting
        return sortingA - sortingB
      })
  )
}
