import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { isWebview } from 'utils/browser'
import { parseQuery } from 'utils/router'
import { useSatuEventTracker } from 'utils/satuEvent'

export interface TrackerTriggerProps {
  eventTrigger: 'REFKOM_GUIDE_PAGE' | 'REFKOM_FORM_PAGE'
}

const TRACKER_EVENT_NAME = 'guru_kinerja_refkom_v1'
const DEFAULT_TIMEOUT = 5000 // 5 seconds
export const TRACKER_REFKOM_QUERY_SOURCE_KEY = 'source'

const TRACKER_REFKOM_QUERY_SOURCE_VALUE = {
  landing: 'WEB_HOMEPAGE',
  pmm_home: 'HOMEPAGE',
  refkom_home: 'HOMEPAGE_REFKOM',
  onboarding: 'REFKOM_ONBOARDING_PAGE',
  refkom_competency: 'REFKOM_SUBCOMPETENCY_RESULT_PAGE',
  assessment: 'REFKOM_ASSESSMENT_MENU_PAGE',
  assessment_form: 'REFKOM_ASSESSMENT_PAGE',
  assessment_guide: 'REFKOM_ASSESSMENT_GUIDE_PAGE',
  assessment_profile: 'REFKOM_PROFILE_CONFIRMATION_PAGE',
}

export const useSETrackEventRefkom = () => {
  const router = useRouter()
  const { SETrackEvent, SETransformAttrs } = useSatuEventTracker()

  const sourceParam = useMemo(() => {
    const source = parseQuery<string>(
      router.query?.[TRACKER_REFKOM_QUERY_SOURCE_KEY]
    )
    const value = TRACKER_REFKOM_QUERY_SOURCE_VALUE[source]
    return value ?? 'EXTERNAL_LINK'

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  const trackOnboardingOpen = ({ pageIndex }: { pageIndex: number }) => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'REFKOM_ONBOARDING_PAGE',
        activity_type: 'OPEN_ONBOARDING_PAGE',
        event_trigger: sourceParam,
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          page_index: pageIndex,
        }),
      },
    })
  }

  const trackHomeRefleksiOpen = (variant) => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'HOMEPAGE_REFKOM',
        activity_type: 'OPEN_REFKOM_HOMEPAGE',
        event_trigger: sourceParam,
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          variant,
        }),
      },
    })
  }

  const trackHomeRefleksiInactiveAssessmentOpen = ({ subCompetency }) => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'REFKOM_POPUP_INACTIVE_ASSESSMENT_PAGE',
        activity_type: 'OPEN_POPUP_INACTIVE_ASSESSMENT_PAGE',
        event_trigger: 'REFKOM_ASSESSMENT_MENU_PAGE',
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          subcompetency: subCompetency,
        }),
      },
    })
  }

  const trackAsessmentMenuOpen = () => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'REFKOM_ASSESSMENT_MENU_PAGE',
        activity_type: 'OPEN_REFKOM_ASSESSMENT_MENU_PAGE',
        event_trigger: sourceParam,
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
        }),
      },
    })
  }

  const trackAsessmentGuideOpen = ({ subCompetency, variant }) => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'REFKOM_ASSESSMENT_GUIDE_PAGE',
        activity_type: 'OPEN_REFKOM_ASSESSMENT_GUIDE',
        event_trigger: sourceParam,
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          variant,
          subcompetency: subCompetency,
        }),
      },
    })
  }

  const trackAssessmentItemCheckOpen = ({ subCompetency }) => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'REFKOM_ITEM_CHECK_POPUP',
        activity_type: 'OPEN_REFKOM_ITEM_CHECK_FAILED_POPUP',
        event_trigger: 'REFKOM_ASSESSMENT_PAGE',
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          subcompetency: subCompetency,
        }),
      },
    })
  }

  const trackSubCompetencyResultOpen = ({ subCompetency }) => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'REFKOM_SUBCOMPETENCY_RESULT_PAGE',
        activity_type: 'OPEN_SUBCOMPETENCY_RESULT_PAGE',
        event_trigger: sourceParam,
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          subcompetency: subCompetency,
        }),
      },
    })
  }

  const trackIndicatorResultOpen = ({ subCompetency, indicator }) => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'REFKOM_INDICATOR_RESULT_PAGE',
        activity_type: 'OPEN_INDICATOR_RESULT_PAGE',
        event_trigger: sourceParam,
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          subcompetency: subCompetency,
          indicator: indicator,
        }),
      },
    })
  }

  const trackClickRefkomLearningRecommendation = ({
    subCompetency,
    indicator,
    contentCollectionId,
    index,
    callback,
  }) => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: false,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      callback,
      eventParams: {
        screen_name: 'REFKOM_INDICATOR_RESULT_PAGE',
        activity_type: 'CLICK_REFKOM_LEARNING_RECOMMENDATION',
        event_trigger: sourceParam,
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          subcompetency: subCompetency,
          indicator: indicator,
          content_collection_id: contentCollectionId,
          index: index,
        }),
      },
    })
  }

  const trackAssessmentProfileConfirmationOpen = () => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'REFKOM_PROFILE_CONFIRMATION_PAGE',
        activity_type: 'OPEN_REFKOM_PROFILE_CONFIRMATION_PAGE',
        event_trigger: sourceParam,
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
        }),
      },
    })
  }

  const trackClickAssessmentEditProfile = () => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'REFKOM_PROFILE_CONFIRMATION_PAGE',
        activity_type: 'CLICK_EDIT_REFKOM_PROFIL_BUTTON',
        event_trigger: 'REFKOM_PROFILE_CONFIRMATION_PAGE',
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
        }),
      },
    })
  }

  const trackClickRedoAssessmentButton = ({ subCompetency }) => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'REFKOM_ITEM_CHECK_POPUP',
        activity_type: 'CLICK_REDO_ASSESSMENT_BUTTON',
        event_trigger: 'REFKOM_ASSESSMENT_PAGE',
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          subcompetency: subCompetency,
        }),
      },
    })
  }

  const trackClickHelpdeskButton = ({ subCompetency, callback }) => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: false,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      callback,
      eventParams: {
        screen_name: 'REFKOM_ITEM_CHECK_POPUP',
        activity_type: 'CLICK_HELPDESK_BUTTON',
        event_trigger: 'REFKOM_ASSESSMENT_PAGE',
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          subcompetency: subCompetency,
        }),
      },
    })
  }

  const trackClickTakeAssessment = ({ subCompetency, variant }) => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'REFKOM_POPUP_INACTIVE_ASSESSMENT_PAGE',
        activity_type: 'CLICK_TAKE_ASSESSMENT_BUTTON',
        event_trigger: 'REFKOM_ASSESSMENT_MENU_PAGE',
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          variant,
          subcompetency: subCompetency,
        }),
      },
    })
  }

  const trackClickAssessmentCard = ({ subCompetency, variant }) => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'HOMEPAGE_REFKOM',
        activity_type: 'CLICK_ASSESSMENT_CARD',
        event_trigger: 'HOMEPAGE_REFKOM',
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          variant,
          subcompetency: subCompetency,
        }),
      },
    })
  }

  const trackAssessmentSuccess = ({ subCompetency }) => {
    SETrackEvent({
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
      eventName: TRACKER_EVENT_NAME,
      sendUserData: true,
      eventParams: {
        screen_name: 'REFKOM_FINISH_ASSESSMENT_PAGE',
        activity_type: 'CLICK_UNDERSTAND_BUTTON',
        event_trigger: 'REFKOM_POPUP_INACTIVE_ASSESSMENT_PAGE',
        attributes: SETransformAttrs({
          is_webview: [!!isWebview() ? 1 : 0, 'int'],
          subcompetency: subCompetency,
        }),
      },
    })
  }

  return {
    trackOnboardingOpen,
    trackHomeRefleksiOpen,
    trackHomeRefleksiInactiveAssessmentOpen,
    trackAsessmentMenuOpen,
    trackAsessmentGuideOpen,
    trackSubCompetencyResultOpen,
    trackIndicatorResultOpen,
    trackAssessmentProfileConfirmationOpen,
    trackAssessmentItemCheckOpen,
    trackClickAssessmentEditProfile,
    trackClickRefkomLearningRecommendation,
    trackClickRedoAssessmentButton,
    trackClickHelpdeskButton,
    trackClickTakeAssessment,
    trackAssessmentSuccess,
    trackClickAssessmentCard,
  }
}
