import axios from 'axios'
import { useQuery } from 'react-query'

import { baseUrl } from 'configs/api'
import { api, handleCommonErrors } from 'utils/api'
import { useAuth } from 'utils/auth'
import { InternalServerError, NetworkError } from 'utils/customError'

import type { AxiosResponse } from 'axios'
import type { UseQueryOptions } from 'react-query'
import type { TBannerV2Response } from 'types/home-banner'

const basePath = '/guru/teachers/v1alpha1/banners'

function handleError(status, path, error) {
  if (error?.message === 'Network Error') {
    throw new NetworkError(path, error)
  } else if ([400, 403].includes(status) || status >= 500) {
    throw new InternalServerError(path, error)
  } else {
    handleCommonErrors(status, path, error)
  }
}

export function useAPIGetHomeBanners(options?: UseQueryOptions<any>) {
  const { session } = useAuth()
  return useQuery<TBannerV2Response>(
    basePath,
    async () => {
      let response: TBannerV2Response
      if (Boolean(session)) {
        response = await api<TBannerV2Response>(basePath)
      } else {
        try {
          response = (
            await axios.get<any, AxiosResponse<TBannerV2Response>>(
              `${baseUrl}${basePath}`
            )
          ).data
        } catch (error) {
          handleError(error?.response?.status, basePath, error.response)
        }
      }
      return response
    },
    {
      staleTime: 60 * 60 * 1000, // 1 hour
      cacheTime: 60 * 60 * 1000, // 1 hour
      useErrorBoundary: false,
      retry: false,
      ...(options ?? {}),
    }
  )
}
