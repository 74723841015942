import Link from 'next/link'
import { useEffect, useRef } from 'react'

import { Button } from '@wartek-id/button-v1'

import type { TIKMActivityEvent } from 'types/content-platform'
import type {
  TSETrackDaftarKurikulumMerdekaClickActivities,
  TSETrackDaftarKurikulumMerdekaLoadedActivities,
} from 'types/pendaftaran-ikm'

interface PropsIKMCard {
  title: string
  subtitle: string
  buttonTitle: string
  imageUrl: string
  activityEvent: TIKMActivityEvent
  redirectionUrl: string
  loadTracker: (
    activityType: TSETrackDaftarKurikulumMerdekaLoadedActivities
  ) => void
  clickTracker: (
    activityType: TSETrackDaftarKurikulumMerdekaClickActivities
  ) => void
}

const PendaftaranIKMCard = ({
  title,
  subtitle,
  buttonTitle,
  imageUrl,
  redirectionUrl,
  activityEvent,
  loadTracker,
  clickTracker,
}: PropsIKMCard) => {
  const alreadyTracked = useRef<boolean>(false)
  useEffect(() => {
    if (activityEvent && !alreadyTracked.current) {
      alreadyTracked.current = true
      loadTracker(`LOAD_${activityEvent}`)
    }
  }, [loadTracker, activityEvent])

  return (
    <div
      data-testid="pendaftaran-ikm-card"
      className="col-span-2"
      onClick={() => clickTracker(`CLICK_${activityEvent}`)}
    >
      <Link href={redirectionUrl} passHref>
        <a data-testid="lihat-detail-card-link">
          <div className="shadow-card px-4 py-3 rounded-md bg-white flex flex-col justify-between">
            <div className="flex mb-3">
              <p
                className="flex-grow text-[17px]"
                data-testid="ikm-registration-title"
              >
                {title}
              </p>
            </div>
            <div className="flex gap-3">
              <div className="flex-1 flex-grow">
                <p
                  className="text-[13px] mb-2"
                  data-testid="ikm-registration-content"
                >
                  {subtitle}
                </p>
                <div className="flex w-fit">
                  <Button
                    color="black"
                    size="sm"
                    data-testid="ikm-registration-cta"
                    fullWidth
                  >
                    {buttonTitle}
                  </Button>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <img src={imageUrl} width="60" alt={title} />
              </div>
            </div>
          </div>
        </a>
      </Link>
    </div>
  )
}

export default PendaftaranIKMCard
