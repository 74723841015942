import { getSession } from 'utils/auth'
import { useSatuEventTracker } from 'utils/satuEvent'

const KSPS_TRACKER_EVENT_NAME = 'guru_ksps_pmm_fe_user_action_v1'
const DEFAULT_EVENT_VERSION = 1

interface AdditionalInfo {
  key: string
  value: string
}

interface KSPSTrackerParams {
  event_name: string
  additional_info?: AdditionalInfo[]
}

const useKSPSTrackEvent = () => {
  const { SETrackEvent } = useSatuEventTracker()
  const session = getSession()

  const defaultEventParams = {
    user_id: session?.user.id,
    email: session?.user.email,
    session_id: session?.guruToken.slice(-16, session.guruToken.length),
    event_version: DEFAULT_EVENT_VERSION,
    event_params_page_location: window.location.href,
    event_params_page_referrer: document.referrer,
  }

  const KSPSTrackEvent = (eventParams: KSPSTrackerParams) => {
    SETrackEvent({
      eventName: KSPS_TRACKER_EVENT_NAME,
      sendAttributes: false,
      eventParams: {
        ...defaultEventParams,
        ...eventParams,
      },
    })
  }

  return {
    KSPSTrackEvent,
  }
}

export default useKSPSTrackEvent
