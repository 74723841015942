import React from 'react'
import NextImage from 'next/image'
import Link from 'next/link'

import { Text } from '@wartek-id/text'
import { Button } from '@wartek-id/button-v1'

import { useMutateAPIGetKSSelectionCandidate } from 'api/bcks/useAPIBCKSInvitation'
import {
  SELECTION_STATES_ANNOUNCEMENT,
  SELECTION_STATES_FINAL_PAGE,
} from 'app/BCKSPage/constants'
import { getDayToDeadlineState } from 'app/BCKSPage/utils/date'
import useKSPSTrackEvent from 'app/BCKSPage/hooks/useKSPSTrackEvent'

import type {
  ResponseSuccessKSSelectionCandidate,
  SelectionCandidateState,
} from 'api/bcks/useAPIBCKSInvitation'

interface selectionCardStateProps {
  deadlineDate: string
  show: boolean
  selectionCandidateState: SelectionCandidateState
}

interface InvitationStateMap {
  title: string
  buttonText: string
  imageSrc: string
  targetUrl: string
}

const DEFAULT_MAP: InvitationStateMap = {
  title:
    'Dalam proses pengecekan berkas. Mohon cek rincian tahapan secara berkala.',
  buttonText: 'Cek rincian tahapan',
  imageSrc: '/images/bcks/img-files-magnifying-glass-progress.png',
  targetUrl: '/bcks/invitation/final',
}

// States:
// 1. If state !== finished/invitation/documentSubmission, return default
// 2. If date === within and state === submission, return targetUrl to precollection, state 2
// 3. If date === within and state !== submission, i.e. invitation, return targetUrl to /bcks/invitation, state 2
// 4. If date === exceed, return state 3
const getUIKSStateMap = (
  state: SelectionCandidateState,
  date: string
): InvitationStateMap => {
  if (SELECTION_STATES_ANNOUNCEMENT.includes(state)) {
    return {
      title: 'Pemberitahuan Hasil Seleksi Kepala Sekolah',
      buttonText: 'Cek selengkapnya',
      imageSrc: '/images/bcks/img-announcement-speaker.png',
      targetUrl: '/bcks/invitation/final',
    }
  }
  if (SELECTION_STATES_FINAL_PAGE.includes(state)) return DEFAULT_MAP

  const deadlineState = getDayToDeadlineState(date)
  let stateMap: InvitationStateMap = {} as InvitationStateMap
  let targetUrl = '/bcks/invitation'

  if (state === 'documentSubmission') {
    targetUrl = '/bcks/invitation/precollection'
  }

  const shownDate = new Date(date)
    .toLocaleString('id-ID', {
      timeZone: 'Asia/Jakarta',
      dateStyle: 'long',
      timeStyle: 'short',
    })
    .replace('.', ':') // Replace period in time, ex: 23.59 to 23:59

  if (deadlineState === 'withinDeadline') {
    stateMap = {
      title: `Respon undangan dan kumpulkan berkas maksimal ${shownDate} WIB`,
      buttonText: 'Unggah Berkas',
      imageSrc: '/images/bcks/img-collect-files-folder.png',
      targetUrl,
    }
  }

  if (deadlineState === 'exceedDeadline') {
    stateMap = {
      title: 'Anda terpilih dan diundang untuk mengikuti seleksi!',
      buttonText: 'Lihat Undangan',
      imageSrc: '/images/bcks/img-invitation-default.png',
      targetUrl: '/bcks/invitation',
    }
  }

  return stateMap
}

export const KSSelectionCard = () => {
  const { KSPSTrackEvent } = useKSPSTrackEvent()
  const [selectionCardState, setSelectionCardState] =
    React.useState<selectionCardStateProps>({
      show: false,
      deadlineDate: '',
      selectionCandidateState: 'invitation',
    })
  const getCandidateMutation = useMutateAPIGetKSSelectionCandidate()

  const uiKSState = getUIKSStateMap(
    selectionCardState.selectionCandidateState,
    selectionCardState.deadlineDate
  )

  const handleGetKSCandidateSelection = () => {
    getCandidateMutation.mutate(null, {
      onSuccess: (data: ResponseSuccessKSSelectionCandidate) => {
        setSelectionCardState({
          show:
            !!data.deadlineDate || data.selectionCandidateState === 'finished',
          deadlineDate: data.deadlineDate,
          selectionCandidateState: data.selectionCandidateState,
        })
      },
    })
  }

  React.useEffect(() => {
    handleGetKSCandidateSelection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!selectionCardState.show) {
    return null
  }

  return (
    <div className="px-4">
      <Text
        variant="heading-md"
        className="mb-2"
        data-testid="ks-selection-card"
      >
        Seleksi Kepala Sekolah
      </Text>
      <div className="rounded shadow p-4 flex justify-between bg-white">
        <div className="flex flex-col">
          <Text data-testid="ks-selection-card-title">{uiKSState.title}</Text>

          <div>
            <Link passHref href={uiKSState.targetUrl}>
              <Button
                type="button"
                className="mt-2"
                color="black"
                size="sm"
                data-testid="ks-selection-card-link"
                onClick={() => {
                  KSPSTrackEvent({
                    event_name: 'click_ksps_banner',
                    additional_info: [
                      {
                        key: 'cta_banner',
                        value: uiKSState.buttonText,
                      },
                    ],
                  })
                }}
              >
                {uiKSState.buttonText}
              </Button>
            </Link>
          </div>
        </div>
        <div className="flex flex-col justify-end shrink-0">
          <NextImage
            data-testid="ks-selection-card-image"
            src={uiKSState.imageSrc}
            width={60}
            height={60}
            alt="Perangkat Ajar"
          />
        </div>
      </div>
    </div>
  )
}
