import NextImage from 'next/image'
import NextLink from 'next/link'

import { Icon } from '@wartek-id/icon'
import { Text } from '@wartek-id/text'

import SectionGroup from 'app/Homepage/components/SectionGroup'
import { useGATrackEvent } from 'app/Homepage/hooks/useGATrackEvent'
import { useSETrackEvent } from 'app/Homepage/hooks/useSETrackEvent'

const TentangKurikulumMerdeka = () => {
  const { GATrackEventHomePage } = useGATrackEvent()
  const { SETrackEventHomePage } = useSETrackEvent()

  const trackEventHomePage = (activityType: string): void => {
    GATrackEventHomePage({
      activityType,
    })
    SETrackEventHomePage(activityType)
  }

  return (
    <div className="px-4 pb-6">
      <SectionGroup title="Dokumen Rujukan" className="!py-0">
        <NextLink href="/tentang-kurikulum-merdeka" passHref>
          <a
            data-testid="home-menu-kurikulum"
            className="col-span-2"
            onClick={() => {
              trackEventHomePage('CLICK_PARADIGMA')
            }}
          >
            <div className="flex justify-between px-4 py-3 bg-white rounded-md shadow-card gap-x-2">
              <div className="flex flex-col justify-start flex-grow-0">
                <NextImage
                  layout="fixed"
                  src="/icon/menu-curriculum.svg"
                  alt="Pengenalan Kurikulum Merdeka"
                  width={32}
                  height={32}
                />
              </div>
              <div className="flex flex-col justify-center flex-grow">
                <Text>Pengenalan Kurikulum Merdeka</Text>
                <Text variant="body-sm" color="subdued">
                  Lihat Panduan, Referensi, Fakta dan Perbandingan Kurikulum.
                </Text>
              </div>
              <div className="flex flex-col justify-center">
                <Icon color="informational">chevron_right</Icon>
              </div>
            </div>
          </a>
        </NextLink>
        <NextLink href="/dokumen" passHref>
          <a
            data-testid="home-menu-dokumen-rujukan"
            className="col-span-2"
            onClick={() => {
              trackEventHomePage('CLICK_KUMPULAN_DOKUMEN_ENTRY_POINT')
            }}
          >
            <div className="flex justify-between px-4 py-3 bg-white rounded-md shadow-card gap-x-2">
              <div className="flex flex-col justify-start flex-grow-0">
                <NextImage
                  layout="fixed"
                  src="/icon/document-collection.svg"
                  alt="Kumpulan Dokumen"
                  width={32}
                  height={32}
                />
              </div>
              <div className="flex flex-col justify-center flex-grow">
                <Text>Panduan, Regulasi dan Inspirasi Penerapan</Text>
                <Text variant="body-sm" color="subdued">
                  Peningkatan Kompetensi, Pengelolaan Kinerja, dan lain-lain.
                </Text>
              </div>
              <div className="flex flex-col justify-center">
                <Icon color="informational">chevron_right</Icon>
              </div>
            </div>
          </a>
        </NextLink>
      </SectionGroup>
    </div>
  )
}

export default TentangKurikulumMerdeka
